import { Button, Drawer, UpdateIcon } from '@uy3/web-components';
import { Error } from 'components/Errors/Error';
import { ApiResponseError, ToastType, iconSx, useApiRequest } from 'contexts/apiRequestContext';
import { useAccountOperatorsList, useDeleteBankAccountOperatorMutation, useDeleteBankAccountRequestOperator } from 'contexts/bankAccount/bankAccountRequest';
import { FormProvider, useFormContext } from 'contexts/formContext';
import { useGenerateSessionIdMutation, useIdentity } from 'contexts/identityContext';
import { useOperatorList } from 'contexts/OperatorContext';
import { useEffect, useState } from 'react';
import { AccountOperatorsList } from './AccountOperatorsList';
import { AccountOperatorsForm } from './AccountOperatorsFormDrawer/AccountOperatorsForm';
import {
    defaultValuesAccountOperators,
    validationSchemaAccountOperators,
} from './AccountOperatorsFormDrawer/AccountOperatorsFormSchema';
import { Grid } from '@mui/material';
import { postBankAccountOperator } from 'services/accounts/bankAccount/bankAccount';
import { useParams } from 'react-router';
import { activeTheme } from 'services/theme';
import { RefreshProgress } from 'components/RefreshProgress';
import { IMFAState } from 'contexts/bankAccount/bankAccountType';
import { postBankAccountRequestOperator } from 'services/accounts/bankAccountRequest/bankAccountRequest';
import Toast from 'components/Toast/Toast';
import DrawerMFA from 'pages/BankAccount/BankAccountActive/BankAccountView/Tabs/DrawerMFA/DrawerMFA';
import { DeleteOperatorByOperatorIdType } from 'services/accounts/bankAccount/bankAccount.types';
import { GenericListHeader } from 'components/GenericListHeader/GenericListHeader';
import { useUserPermissionData } from 'contexts/userContext';
import { checkActionsPerms } from 'services/permissions';

const theme = activeTheme();

type InformationToEdit = {
    userId: string,
    userIdDisplay: string,
    level: string
}

export const AccountOperatorsContainer = () => {
    const { bankAccountId } = useParams();
    const { token } = useIdentity();
    const { getValues, watch } = useFormContext();
    const [rowAction, setRowAction] = useState<{ action: string, row: any } | undefined>(undefined);
    const [toast, setToast] = useState<ToastType>({
        title: '',
        severity: undefined,
        open: false
    });
    const { data: permissionsData } = useUserPermissionData();
    const [selectedTenant, setSelectedTenant] = useState<string | undefined>();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const bankAccountNumber = getValues('bankAccountNumber') ?? '';
    const { setSubmitError, submitError, submitting, endRequest } = useApiRequest();
    const isEdit = rowAction?.action === 'edit';
    const status = watch('status');
    const isActiveBankAccountRequest = status === 'Active';

    const hasActionPermission = (action: string, resource: string): boolean => {
        if (checkActionsPerms(action, permissionsData, resource)) return true;
        return false;
    };

    const onClose = () => {
        setRowAction(undefined);
        return setSubmitError(undefined);
    };

    const {
        accOperatorsData,
        accOperatorsError,
        accOperatorsStatus,
        refetch,
        accOperatorsIsFetch,
    } = useAccountOperatorsList(bankAccountId!, { page, size: rowsPerPage });
    const { operatorsAutoCompleteProps } = useOperatorList(
        { page: 0, size: 10 },
        'always',
        selectedTenant
    );

    const handleSuccessMessage = (action: string) => {
        setRowAction(undefined);
        const successMessage: { [action: string]: string } = {
            "delete": "Sucesso ao excluir o operador",
            "create": "Operador criado com sucesso",
            "edit": "Sucesso ao aditar o operador"
        };
        refetch();
        onClose();
        endRequest(true);
        const description = successMessage[action];
        setToast({ open: true, title: "Sucesso!", description, severity: "success" })
    }
    const onError = (response: ApiResponseError) => {
        setSubmitError(response)
        endRequest(false);
    };

    const { mutateGenerateSessionId } = useGenerateSessionIdMutation();
    const { mutate: deleteOperatorBankAccount } = useDeleteBankAccountOperatorMutation(() => handleSuccessMessage('delete'), onError);
    const { mutateAsync: deleteOperatorBankAccountRequest } = useDeleteBankAccountRequestOperator(() => handleSuccessMessage('delete'), onError)

    useEffect(() => { refetch() }, [bankAccountId, refetch]);

    const handleSubmit = async (values: any) => {
        let payload = {
            userId: values?.userId,
            level: values?.level,
            tenant: values?.tenant
        };

        const typeMessageSuccess = rowAction?.action === 'edit' ? "edit" : 'create'

        if (isActiveBankAccountRequest) {
            return await postBankAccountOperator(bankAccountNumber, payload, token!)
                .then(() => {
                    onClose();
                    handleSuccessMessage(typeMessageSuccess)
                    return refetch();
                }).catch((error: ApiResponseError) => setSubmitError(error));
        }
        return await postBankAccountRequestOperator(bankAccountId!, payload, token!)
            .then(() => {
                onClose();
                handleSuccessMessage(typeMessageSuccess)
                return refetch();
            }).catch((error: ApiResponseError) => setSubmitError(error));
    };

    const handleDeleteOperatorById = (values: IMFAState) => {
        const { code, password } = values;
        const { id } = rowAction?.row;

        mutateGenerateSessionId({
            userPassword: password,
            then: ({ data: sessionId }) => {
                const payload = {
                    accountNumber: bankAccountNumber,
                    code,
                    operatorId: id,
                    sessionId
                } as DeleteOperatorByOperatorIdType;

                if (isActiveBankAccountRequest) {
                    return deleteOperatorBankAccount(payload);
                } else {
                    return deleteOperatorBankAccountRequest({
                        code, 
                        operatorId: id!, 
                        sessionId
                    });
                }
            }
        });
    }

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const onChangePage = (page: number) => setPage(page);

    if (accOperatorsStatus === 'error' || accOperatorsData?.type === 'error')
        return <Error error={accOperatorsError} />

    const levelByDisplay: { [level: string]: string } = {
        "Visualizador": "Viewer",
        "Aprovador Master": "MasterApprover",
        "Aprovador em Conjunto": "JointApprover",
        "Solicitante": "Requester"
    }

    const schema = validationSchemaAccountOperators();
    const operatorData = accOperatorsData?.data[rowAction?.row];
    const defaultValues = isEdit ?
        {
            level: levelByDisplay[operatorData?.levelDisplay],
            tenant: operatorData?.tenant,
            tenantDisplay: operatorData?.tenantDisplay,
            userId: operatorData?.user?.userId,
            userIdDisplay: operatorData?.user?.userIdDisplay
        } as InformationToEdit
        : defaultValuesAccountOperators;

    const RefreshActionComponent = () => {
        return <Button
            variant="text"
            size="large"
            disabled={submitting}
            onClick={() => refetch()}
            sx={{ color: theme.palette.common.black }}
            startIcon={<UpdateIcon sx={iconSx} />}
        >
            Atualizar
        </Button>
    }
    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <Grid mt={3} mb={2}>
                <GenericListHeader
                    title='Acessos concedidos'
                    titleButton='Solicitar permissão'
                    enableAction={hasActionPermission('RequestNewOperator', 'BankAccount')}
                    onClick={() => setRowAction({ action: 'create', row: undefined })}
                    otherActionsComponent={<RefreshActionComponent />}
                />
            </Grid>

            {<RefreshProgress refreshing={accOperatorsIsFetch} />}

            <Grid m='20px 0 '>
                <AccountOperatorsList
                    page={page}
                    queryData={accOperatorsData ?? []}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={onChangeRowsPerPage}
                    setPage={onChangePage}
                    onDeleteOperator={(row) => setRowAction({ action: "delete", row })}
                    onEditOperator={(index) => setRowAction({ action: "edit", row: index })}
                />
            </Grid>
            <Drawer
                anchor="right"
                title={isEdit ? `Editar operador` : "Solicitar permissão"}
                open={rowAction?.action === 'create' || rowAction?.action === 'edit'}
                onClose={onClose}
            >
                <FormProvider
                    onChangeField={[{
                        fieldName: 'tenant',
                        delegate: (value: string) => setSelectedTenant(value)
                    }]}
                    validationSchema={schema}
                    defaultValues={defaultValues}
                    onSubmit={handleSubmit}
                >
                    <AccountOperatorsForm
                        submitError={submitError}
                        onClose={onClose}
                        submitting={submitting}
                        listOperator={operatorsAutoCompleteProps}
                    />
                </FormProvider>
            </Drawer>

            <Drawer
                anchor='right'
                title={`Excluir ${rowAction?.row?.user?.userIdDisplay ?? ""}`}
                open={rowAction?.action === 'delete'}
                onClose={onClose}
            >
                <DrawerMFA onClose={onClose} onSubmit={handleDeleteOperatorById} />
            </Drawer >
        </>
    );
};
