/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, IconButton, Stack, styled } from '@mui/material';
import { BankingIcon, PercentageIcon, Typography } from '@uy3/web-components';
import { SelectFormField, TextFormField, DatePickerFormField, SelectOption, CheckboxFormField } from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import PercentageFormField from 'components/Forms/FormFields/PercentageFormField/PercentageFormField';
import { useAppConfig } from 'contexts/appConfig';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { useCreditNoteFormContext } from 'pages/Product/CreditProduct/CreditProductForm/CreditProductForm';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { cpfMask } from 'utils/constants';
import { isEmpty } from 'lodash';
import { useShowValues } from 'contexts/showValuesContext';
import { useEffect, useMemo, useState } from 'react';
import Toast from 'components/Toast/Toast';
import { ToastType, toastState, useApiRequest } from 'contexts/apiRequestContext';
import ConfigurableHiddenField from 'components/FormFieldHidden';
import { getFieldOptions } from 'helpers/methods/formFieldConfiguration';
import { TooltipComponent } from 'components/Tooltip/Tooltop';
import { getPayrollLoanDetails, setPaymentTransferAndCutoffDay } from './setFirstPaymentDate';
import { CreditProductReadModel, updateAmortizationForProduct } from 'services/creditProduct/types/creditProductReadModel';

export const StyledFieldSimulation = styled('div')(() => ({
    '@media print': {
        display: 'none',
    },
    marginRight: 25,
    marginLeft: 5,
}));

type ButtonIconChangeComissionTypeProps = {
    agentCommissionType: string | number;
    changeAgentComissioType: () => void;
};

interface IPropsToMenuSimulation {
    isSimulation?: boolean;
    productList?: AutocompleteOptionList;
    tomadorList?: AutocompleteOptionList;
    isLoading?: boolean;
    setProductId?: React.Dispatch<React.SetStateAction<string | undefined>>;
    defaultValues?: any;
}

const ButtonIconChangeComissionType = ({
    agentCommissionType,
    changeAgentComissioType: handleChangeComissionType
}: ButtonIconChangeComissionTypeProps) => {
    return (
        <IconButton
            onClick={handleChangeComissionType}
            sx={{
                width: 15,
                height: 15,
                position: 'absolute',
                right: 10,
                bottom: 30,
                zIndex: 10,
            }}
        >
            {agentCommissionType === 'Percentage' ? (
                <BankingIcon sx={{ width: 12, height: 12 }} />
            ) : (
                <PercentageIcon sx={{ width: 12, height: 12 }} />
            )}
        </IconButton>
    );
};

const OperationsTab = ({
    isSimulation = false,
    productList,
    tomadorList,
    defaultValues
}: IPropsToMenuSimulation) => {
    const [toast, setToast] = useState<ToastType>(toastState);
    const { appConfig } = useAppConfig();
    const { watch, setValue, readOnly, getValues } = useFormContext();
    const { product } = useCreditNoteFormContext();
    const { showValues } = useShowValues();
    const { submitError } = useApiRequest();

    const changeFormValues = (data: any) => {
        if (data) {
            for (const [key, value] of Object.entries(data)) {
                if (key === 'amortization') {
                    //@ts-ignore
                    for (const [subKey, subValue] of Object.entries(value)) {
                        if (subKey === 'apr' || subKey === 'numberOfPayments' || subKey === "firstPaymentDate") continue;
                        setValue(`amortization.${subKey}`, subValue);
                        console.log('updated amortization value', { subKey, subValue });
                    }
                } else if (key !== 'bankAccountId' && key !== 'personId') {
                    setValue(key, value);
                    console.log('updated form value', { key, value });
                }
            }
        }
    };

    useEffect(() => {
        if (product && !submitError) {
            return changeFormValues(defaultValues);
        }
    }, [product]);

    const instrumentType = product?.instrumentType?.toLowerCase() ?? '';
    const amortizationType = product?.amortizationType?.toLowerCase() ?? 'cleanprice';
    const agentCommissionType = watch('amortization.agentCommission.type');
    const allowFinanceTaxExemption = product?.allowFinanceTaxExemption;

    const isFinanceTaxExemptedTrue = () => {
        if ((amortizationType || instrumentType) === 'CommercialPapper' || allowFinanceTaxExemption === true) {
            return true;
        }
        return false;
    };

    const calcType = watch('amortization.calculateByValueType');

    useMemo(() => {
        if (isFinanceTaxExemptedTrue()) {
            setValue('amortization.financeTaxExempted', true);
        } else {
            setValue('amortization.financeTaxExempted', false);
        }
    }, [product]);

    const optionsMonths = [
        { label: 'Janeiro', value: 'January' },
        { label: 'Fevereiro', value: 'February' },
        { label: 'Março', value: 'March' },
        { label: 'Abril', value: 'April' },
        { label: 'Maio', value: 'May' },
        { label: 'Junho', value: 'June' },
        { label: 'Julho', value: 'July' },
        { label: 'Agosto', value: 'August' },
        { label: 'Setembro', value: 'September' },
        { label: 'Outubro', value: 'October' },
        { label: 'Novembro', value: 'November' },
        { label: 'Dezembro', value: 'December' },
    ];

    const handlePersonLink = (): string => {
        const personId = watch('personId');
        if (watch('personDiscriminator') === 'LegalPerson') {
            return `/pessoas-juridicas/todas/${personId}`;
        } else return `/pessoas-fisicas/todas/${personId}`;
    };

    const changeAgentComissionType = () => {
        if (agentCommissionType === 'Percentage') {
            setValue('amortization.agentCommission.type', 'Absolute');
        } else {
            setValue('amortization.agentCommission.type', 'Percentage');
        }
        setValue('amortization.agentCommission.amount', 0);
    };

    const formFieldsConfig = appConfig?.FORM_FIELDS;
    const isPriceAmortization = amortizationType === 'price';
    const isSacAmortization = amortizationType === 'sac';
    const isPriceOrSacAmortization = isPriceAmortization || isSacAmortization;
    const isPriceCoefficientAmortization = amortizationType === "pricecoefficient";
    const warrantyRegistrationOffice = product?.warrantyRegistrationOffice?.toLowerCase() ?? "none";
    const isPublicPayrollLoan = instrumentType === 'publicpayrollloan';
    const isSiapeOffice = warrantyRegistrationOffice === 'siape' && isPublicPayrollLoan;
    const isNoWarrantyOffice = warrantyRegistrationOffice === 'none' && amortizationType !== 'fgts';

    const siapeCalculateOptions = [
        { label: 'Valor Liberado', value: 'Liquid' },
        { label: 'Valor da Parcela', value: 'Payment' }
    ];

    const calculateByValueOptions = [
        { label: 'Valor Bruto', value: 'Gross' },
        { label: 'Valor Líquido', value: 'Liquid' },
        isPriceAmortization
            ? { label: 'Valor da Parcela', value: 'Payment' }
            : undefined,
    ].filter((i) => i !== undefined) as SelectOption[];

    const calculateByValueTypeOption = getFieldOptions("amortization.calculateByValueType", formFieldsConfig)
        || isSiapeOffice ? siapeCalculateOptions : calculateByValueOptions;

    const warrantyList = watch('warranty') ?? [];
    const { payrollAgreements } = product ?? {};

    const {
        paymentTransferDayValue,
        warrantyRegistrationCutoffDayValue,
    } = getPayrollLoanDetails(
        warrantyList,
        payrollAgreements!,
        isPublicPayrollLoan
    );

    const startDate = watch('amortization.startDate') ?? new Date().toString();
    useEffect(() => {
        if (isPublicPayrollLoan && paymentTransferDayValue && warrantyRegistrationCutoffDayValue) {
            setPaymentTransferAndCutoffDay(
                startDate,
                paymentTransferDayValue,
                warrantyRegistrationCutoffDayValue,
                setValue
            );
        }
    }, [startDate, warrantyList]);

    return (
        <StyledFieldSimulation>
            <Toast toast={toast} setToast={setToast} />
            <Box py={3}>
                <Typography
                    variant="xxl"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Parâmetros da operação
                </Typography>
                <Grid container spacing={3} mt={2}>
                    {isSimulation && (
                        <>
                            <Grid item xs={3}>
                                <AutocompleteField
                                    name="productId"
                                    label="Produto"
                                    displayName="productIdDisplay"
                                    endIconType="link"
                                    link={`/cadastro/produtos-credito/${watch('productId')}`}
                                    showEndIcon={watch('productId')}
                                    onChange={(a, b) => {
                                        let unk = b as unknown;
                                        let product = unk as CreditProductReadModel;
                                        setTimeout(() => {
                                            if (product != null) {
                                                const updated = updateAmortizationForProduct(product, setValue, appConfig, getValues);
                                                if (updated) {
                                                    const messsageToast = "Ajustamos sua simulação de acordo com os parâmetros máximos de taxa e prazo do produto selecionado";
                                                    setToast({ open: true, title: "Atenção!", description: messsageToast, severity: 'info' });
                                                }
                                            }
                                        }, 1500);
                                    }}
                                    required
                                    {...productList!}
                                />
                            </Grid>
                            <ConfigurableHiddenField fieldName="legalPerson"  hidden={isSiapeOffice}>
                                <Grid item xs={3}>
                                    <SelectFormField
                                        name="legalPerson"
                                        label="Tipo de Pessoa"
                                        fullWidth
                                        required
                                        options={[
                                            { label: 'Pessoa Física', value: false },
                                            { label: 'Pessoa Jurídica', value: true },
                                        ]}
                                    />
                                </Grid>
                            </ConfigurableHiddenField>
                            {amortizationType === 'fgts' && (
                                <>
                                    <Grid item xs={3}>
                                        <AutocompleteField
                                            name="personId"
                                            label="Tomador"
                                            displayName="personDisplay"
                                            endIconType="link"
                                            link={handlePersonLink()}
                                            showEndIcon={watch('personId')}
                                            {...tomadorList!}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextFormField
                                            name="registrationNumber"
                                            label="CPF"
                                            fullWidth
                                            placeholder="000.000.000-00"
                                            autoComplete="registrationNumber"
                                            variant="outlined"
                                            required={isEmpty(
                                                watch('personId') ||
                                                watch('amortization.paymentMonth')
                                            )}
                                            disabled={!isEmpty(watch('personId'))}
                                            InputProps={{
                                                inputComponent: MaskedInput,
                                                inputProps: { mask: cpfMask },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <CurrencyFormField
                                            label="Saldo FGTS"
                                            fullWidth
                                            name="amortization.requestedAmount"
                                            variant="outlined"
                                            disabled={
                                                isEmpty(
                                                    watch('personId') || watch('registrationNumber')
                                                )
                                                    ? false
                                                    : true
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <SelectFormField
                                            label="Mês do aniversário"
                                            name="amortization.paymentMonth"
                                            options={
                                                isEmpty(
                                                    watch('personId') || watch('registrationNumber')
                                                )
                                                    ? optionsMonths
                                                    : [{ label: 'Selecione', value: null }]
                                            }
                                            required={isEmpty(
                                                watch('personId') || watch('registrationNumber')
                                            )}
                                            disabled={
                                                isEmpty(
                                                    watch('personId') || watch('registrationNumber')
                                                )
                                                    ? false
                                                    : true
                                            }
                                        />
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                    {amortizationType !== 'fgts' && (
                        <Grid item xs={3}>
                            <SelectFormField
                                name="amortization.calculateByValueType"
                                label="Tipo de Cálculo"
                                fullWidth
                                required
                                disabled={!showValues || readOnly}
                                options={calculateByValueTypeOption}
                            />
                        </Grid>
                    )}
                    {amortizationType !== 'fgts' && (
                        <Grid item xs={3}>
                            <CurrencyFormField
                                label={calcType === "Payment" ? "Valor da parcela" : "Valor solicitado"}
                                fullWidth
                                name="amortization.requestedAmount"
                                variant="outlined"
                                required
                                disabled={!showValues || readOnly}
                            />
                        </Grid>
                    )}
                    <Grid item xs={3}>
                        <PercentageFormField
                            name="amortization.apr"
                            label={
                                isPriceOrSacAmortization
                                    ? 'Taxa de Juros A.M. (%)'
                                    : 'Taxa de Juros (%)'
                            }
                            variant="outlined"
                            required
                            fullWidth
                        />
                    </Grid>

                    {isPriceOrSacAmortization && (
                        <>
                            <Grid item xs={3}>
                                <TextFormField
                                    name="amortization.numberOfPayments"
                                    label="Quantidade de parcelas"
                                    type="number"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    inputProps={{ min: 0, max: 1000 }}
                                />
                            </Grid>
                            <ConfigurableHiddenField fieldName="amortization.numberOfInterestPayments" hidden={isSiapeOffice}>
                                <Grid item xs={3}>
                                    <TextFormField
                                        name="amortization.numberOfInterestPayments"
                                        label="Carência de principal"
                                        type="number"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        inputProps={{ min: 0, max: 1000 }}
                                    />
                                </Grid>
                            </ConfigurableHiddenField>
                            <ConfigurableHiddenField fieldName="amortization.calculationType" hidden={isSiapeOffice}>
                                <Grid item xs={3}>
                                    <SelectFormField
                                        name="amortization.calculationType"
                                        label="Base de cálculo"
                                        fullWidth
                                        required
                                        options={[
                                            { label: 'Base 252 - Dias úteis ', value: 'V252DiasUteis' },
                                            { label: 'Base 252 - Meses x 21', value: 'V252MesesX21' },
                                            { label: 'Base 360 - Dias corridos', value: 'V360DiasCorridos' },
                                            { label: 'Base 360 - Meses', value: 'V360Meses' },
                                            { label: 'Base 365 - Dias corridos', value: 'V365DiasCorridos' },
                                            { label: 'Base 365 - Meses', value: 'V365Meses' }
                                        ]}
                                    />
                                </Grid>
                            </ConfigurableHiddenField>
                            <ConfigurableHiddenField fieldName="amortization.paymentPeriodicity.every" hidden={isSiapeOffice}>
                                <Grid item xs={3}>
                                    <TextFormField
                                        name="amortization.paymentPeriodicity.every"
                                        label="Pagamento à cada"
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{ min: 0, max: 1000 }}
                                        required
                                    />
                                </Grid>
                            </ConfigurableHiddenField>
                            <ConfigurableHiddenField fieldName="amortization.paymentPeriodicity.periodicity" hidden={isSiapeOffice}>
                                <Grid item xs={3}>
                                    <SelectFormField
                                        name="amortization.paymentPeriodicity.periodicity"
                                        label="Periodicidade do Pagamento"
                                        fullWidth
                                        required
                                        options={[
                                            { label: 'Dias', value: 'Daily' },
                                            { label: 'Meses', value: 'Monthly' },
                                            { label: 'Anos', value: 'Yearly' },
                                        ]}
                                    />
                                </Grid>
                            </ConfigurableHiddenField>
                        </>
                    )}

                    {amortizationType !== 'price' && amortizationType !== 'sac' && (
                        <Grid item xs={3}>
                            <TextFormField
                                name="amortization.termInMonths"
                                label={amortizationType !== 'fgts' ? 'Duração' : 'Parcelas adiantadas'}
                                type="number"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                                fullWidth
                                inputProps={{ min: 0, max: 1000 }}
                            />
                        </Grid>
                    )}

                    {(amortizationType !== 'price' && amortizationType !== 'sac' && amortizationType !== 'fgts') && (
                        <>
                            <ConfigurableHiddenField fieldName="amortization.absAmortizationInMonths" hidden={isSiapeOffice}>
                                <Grid item xs={3}>
                                    <TextFormField
                                        required
                                        name="amortization.absAmortizationInMonths"
                                        label="Carência Amortização"
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{ min: 0, max: 100 }}
                                    />
                                </Grid>
                            </ConfigurableHiddenField>
                            <ConfigurableHiddenField fieldName="amortization.absInterestInMonths" hidden={isSiapeOffice}>
                                <Grid item xs={3}>
                                    <TextFormField
                                        name="amortization.absInterestInMonths"
                                        label="Carência Juros"
                                        type="number"
                                        variant="outlined"
                                        fullWidth
                                        inputProps={{ min: 0, max: 100 }}
                                        required
                                    />
                                </Grid>
                            </ConfigurableHiddenField>
                            <ConfigurableHiddenField fieldName="amortization.daysInYear" hidden={isSiapeOffice}>
                                <Grid item xs={3}>
                                    <SelectFormField
                                        label="Base de cálculo"
                                        name="amortization.daysInYear"
                                        required
                                        options={[
                                            { label: '360', value: 360 },
                                            { label: '365', value: 365 },
                                            { label: '252', value: 252 },
                                        ]}
                                    />
                                </Grid>
                            </ConfigurableHiddenField>
                            <ConfigurableHiddenField fieldName="amortization.periodicity" hidden={isSiapeOffice}>
                                <Grid item xs={3}>
                                    <SelectFormField
                                        name="amortization.periodicity"
                                        label="Periodicidade Juros"
                                        fullWidth
                                        required
                                        options={[
                                            { label: 'Mensal', value: 'Monthly' },
                                            { label: 'Anual', value: 'Yearly' },
                                        ]}
                                    />
                                </Grid>
                            </ConfigurableHiddenField>
                        </>
                    )}

                    {amortizationType === 'studentloan' && (
                        <>
                            <Grid item xs={12} sm={3}>
                                <CurrencyFormField
                                    name="amortization.fixedPaymentDuringGraduation"
                                    label="Pagamento durante o curso"
                                    fullWidth
                                    required
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextFormField
                                    required
                                    variant="outlined"
                                    name="amortization.termsUntilGraduation"
                                    label="Parcelas até conclusão"
                                    type="number"
                                    fullWidth
                                    inputProps={{ min: 0, max: 100 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextFormField
                                    required
                                    name="amortization.absAfterGraduation"
                                    label="Carência após conclusão"
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    inputProps={{ min: 0, max: 100 }}
                                />
                            </Grid>
                        </>
                    )}

                    <ConfigurableHiddenField fieldName="amortization.startDate">
                        <Grid item xs={3}>
                            <DatePickerFormField
                                name="amortization.startDate"
                                label="Data de início"
                                fullWidth
                                required
                                disabled={readOnly}
                            />
                        </Grid>
                    </ConfigurableHiddenField>

                    {!!appConfig.PAYMENTDAY_FIELD && ['fgts', 'sac', 'price'].indexOf(amortizationType) === -1 && (
                        <ConfigurableHiddenField fieldName="amortization.paymentDay" hidden={isSiapeOffice}>
                            <Grid item xs={3}>
                                <TextFormField
                                    required
                                    name="amortization.paymentDay"
                                    label="Dia de vencimento"
                                    variant="outlined"
                                    fullWidth
                                    type="number"
                                    inputProps={{ min: 0, max: 31 }}
                                />
                            </Grid>
                        </ConfigurableHiddenField>
                    )}

                    {isPriceOrSacAmortization && (
                        <Grid item xs={3}>
                            <DatePickerFormField
                                name="amortization.firstPaymentDate"
                                label="Data do primeiro pagamento"
                                fullWidth
                                required
                                disabled={readOnly || isSiapeOffice}
                            />
                        </Grid>
                    )}

                    {!!appConfig.CORBAN && (
                        <ConfigurableHiddenField fieldName="amortization.agentCommission.amount" hidden={isSiapeOffice}>
                            <Grid item xs={3}>
                                <Stack position="relative">
                                    {!readOnly && (
                                        <ButtonIconChangeComissionType
                                            agentCommissionType={agentCommissionType}
                                            changeAgentComissioType={changeAgentComissionType}
                                        />
                                    )}
                                    {agentCommissionType === 'Percentage' ? (
                                        <PercentageFormField
                                            name="amortization.agentCommission.amount"
                                            label="CORBAN"
                                            variant="outlined"
                                            required
                                            fullWidth
                                        />
                                    ) : (
                                        <CurrencyFormField
                                            name="amortization.agentCommission.amount"
                                            label="CORBAN"
                                            fullWidth
                                            variant="outlined"
                                        />
                                    )}
                                </Stack>
                            </Grid>
                        </ConfigurableHiddenField>
                    )}

                    {product?.allowFinanceTaxExemption === true && (
                        <ConfigurableHiddenField fieldName="amortization.financeTaxExempted">
                            <Grid item xs={12} sm={3}>
                                <SelectFormField
                                    label="Isentar IOF"
                                    name="amortization.financeTaxExempted"
                                    disabled={!isFinanceTaxExemptedTrue()}
                                    options={[
                                        { label: 'Sim', value: true },
                                        { label: 'Não', value: false },
                                    ]}
                                />
                            </Grid>
                        </ConfigurableHiddenField>
                    )}

                    {isNoWarrantyOffice && amortizationType !== 'receivablesprice' && (
                        <>
                            <ConfigurableHiddenField fieldName="amortization.indexer" hidden={isSiapeOffice}>
                                <Grid item xs={12} sm={3}>
                                    <SelectFormField
                                        label="Indexador Pós-Fixado"
                                        showButtonClearValue={true}
                                        name="amortization.indexer"
                                        options={[
                                            { label: 'CDI', value: 'CDI' },
                                            { label: 'TR', value: 'TR' },
                                            { label: 'INPC', value: 'INPC' },
                                            { label: 'IPCA', value: 'IPCA' },
                                            { label: 'IGPM', value: 'IGPM' },
                                            { label: 'SELIC', value: 'SELIC' }
                                        ]}
                                    />
                                </Grid>
                            </ConfigurableHiddenField>
                            <ConfigurableHiddenField fieldName="amortization.indexerValue">
                                <Grid item xs={12} sm={3}>
                                    <PercentageFormField
                                        label="Percentual Indexador"
                                        name="amortization.indexerValue"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                            </ConfigurableHiddenField>
                        </>
                    )}

                    {isNoWarrantyOffice && amortizationType === 'receivablesprice' && (
                        <>
                            <ConfigurableHiddenField fieldName="amortization.fiduciaryGuarantee" hidden={isSiapeOffice}>
                                <Grid item xs={12} sm={3}>
                                    <PercentageFormField
                                        label="Garantia Fiduciária"
                                        name="amortization.fiduciaryGuarantee"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                            </ConfigurableHiddenField>
                            <ConfigurableHiddenField fieldName="amortization.indexer" hidden={isSiapeOffice}>
                                <Grid item xs={12} sm={3}>
                                    <SelectFormField
                                        label="Indexador Pós-Fixado"
                                        name="amortization.indexer"
                                        showButtonClearValue={true}
                                        options={[
                                            { label: 'CDI', value: 'CDI' },
                                            { label: 'TR', value: 'TR' },
                                            { label: 'INPC', value: 'INPC' },
                                            { label: 'IPCA', value: 'IPCA' },
                                            { label: 'IGPM', value: 'IGPM' },
                                        ]}
                                    />
                                </Grid>
                            </ConfigurableHiddenField>
                            <ConfigurableHiddenField fieldName="amortization.indexerValue" hidden={isSiapeOffice}>
                                <Grid item xs={12} sm={3}>
                                    <PercentageFormField
                                        label="Percentual Indexador"
                                        name="amortization.indexerValue"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                            </ConfigurableHiddenField>
                        </>
                    )}
                </Grid>

                <Grid container spacing={3} mt={2}>
                    {isPriceAmortization && !!product && product?.paymentFixedCosts > 0 && (
                        <Grid item>
                            <CheckboxFormField
                                name="amortization.includePaymentFixedCosts"
                                label="Incluir cliente como Participante de Regime de Previdência"
                            />
                        </Grid>
                    )}
                    {(isPriceOrSacAmortization || isPriceCoefficientAmortization) && (
                        <Grid item>
                            <TooltipComponent
                                title="O ajuste de dias úteis não impacta o cálculo de amortização PRICE para bases mensais ou dias corridos"
                                size="small"
                            >
                                <CheckboxFormField
                                    name="amortization.dueDateOnBusinessDays"
                                    label="Ajustar vencimentos para dias úteis"
                                />
                            </TooltipComponent>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </StyledFieldSimulation>
    );
};

export default OperationsTab;
