import React, { ReactNode } from 'react'
import { Stack } from '@mui/material'
import AccordionContent from 'components/AccordionContent/AccordionContent'
import { useAppConfig } from 'contexts/appConfig'
import { isRootTenancy } from 'helpers/methods/GenericMethods'

type ActionsPermissionsProps = {
    PermissionResource?: ReactNode
    PermissionRootTenancy?: ReactNode
}

export const ActionsPermissions: React.FC<ActionsPermissionsProps> = ({ PermissionResource, PermissionRootTenancy }) => {
    const { appConfig } = useAppConfig();
    const rootTenancy = isRootTenancy(appConfig.TENANT_TYPE);

    return (
        <React.Fragment>
            {PermissionResource !== undefined &&
                <AccordionContent
                    title='Ações do recurso'
                    expandedIndex={0}
                    content={(
                        <Stack margin={3}>
                            {PermissionResource}
                        </Stack>
                    )}
                />}

            {rootTenancy && PermissionRootTenancy !== undefined &&
                <AccordionContent
                    title='Ações exclusivas UY3'
                    expandedIndex={1}
                    content={(
                        <Stack margin={3}>
                            {PermissionRootTenancy}
                        </Stack>
                    )}
                />
            }
        </React.Fragment>
    )
}
