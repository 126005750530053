import { FormProvider } from 'contexts/formContext';
import { defaultValuesDraftPreview, validationSchemaDraftPreview } from './DraftPreviewSchema';
import { Grid, Stack } from '@mui/material';
import { CheckboxFormField } from 'components/Forms/FormFields';
import { Button, CloseIcon, CompleteIcon } from '@uy3/web-components';
import { activeTheme } from 'services/theme';
import { ApiResponseError, iconSx } from 'contexts/apiRequestContext';
import { useDraftpreviewBankAccountRequest } from 'contexts/bankAccount/bankAccountRequest';
import { useParams } from 'react-router';
import { IDraftpreviewBankAccountRequest } from 'services/accounts/bankAccountRequest/bankAccountRequest.types';

const theme = activeTheme();

type DraftPreviewContainerProps = {
  onClose: () => void;
  onSueccess: (response: unknown) => void;
  onError: (response: ApiResponseError) => void;
};

export const DraftPreviewContainer = ({ onClose, onError, onSueccess }: DraftPreviewContainerProps) => {
  const { bankAccountId } = useParams();
  const { mutateAsync } = useDraftpreviewBankAccountRequest(bankAccountId!, onSueccess, onError);
  return (
    <FormProvider
      {...{
        validationSchema: validationSchemaDraftPreview(),
        defaultValues: defaultValuesDraftPreview,
        onSubmit: async (values: IDraftpreviewBankAccountRequest) => {
          await mutateAsync(values)
        },
      }}
    >
      <Stack>
        <Grid>
          <CheckboxFormField name="useWatermark" label="Usar marca d'água" />
        </Grid>
        <Grid>
          <CheckboxFormField name="createPdf" label="Criar PDF" />
        </Grid>

        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={3}
          mt={3}
        >
          <Button
            startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
            variant="outlined"
            size="medium"
            onClick={onClose}
          >
            Fechar
          </Button>
          <Button
            variant="contained"
            name="btn-create-favorite"
            type="submit"
            startIcon={
              <CompleteIcon htmlColor={theme.palette.common.white} sx={iconSx} />
            }
          >
            Confirmar
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
};
