import { ExpandMore } from '@mui/icons-material';
import { AccordionDetails, Grid, Stack, SvgIconProps, SxProps, Theme, TypographyOwnProps } from '@mui/material';
import React, { Fragment, FunctionComponent } from 'react';
import { CustomAccordionSummary, CustomizedAccordion } from './CustomAccordion.styled';
import { Switch, Typography } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import Tooltip from 'components/Tooltip';
import { BackgroundCustom, IconGrid } from 'components/BoxInformation/BoxInformation.style';
import { activeTheme } from 'services/theme';

type SwithAccordionProps = {
    Icon: FunctionComponent<SvgIconProps>;
    name: string;
}

const theme = activeTheme();

export interface IAccordion {
    description: string;
    descriptionVariant?: TypographyOwnProps["variant"];
    sxAccordion?: SxProps<Theme>
    children?: React.ReactNode;
    index: number;
    disabled?: boolean;
    expanded?: boolean;
    tooltipText?: string;
    swithAccordionProps?: SwithAccordionProps | null
}

export const CommissionFormCostsAccordionMetadataKey = 'meta.currentAccordion';

const CustomAccordionContainer: React.FC<IAccordion> = ({
    description,
    children,
    index,
    disabled,
    tooltipText,
    descriptionVariant = "xs",
    sxAccordion = {},
    expanded = undefined,
    swithAccordionProps = null
}) => {
    const { watch, setValue } = useFormContext();
    var currentAccordion = watch(CommissionFormCostsAccordionMetadataKey);

    const useSwithExpandIcon = swithAccordionProps !== null
    const nameSwithValue = swithAccordionProps?.name;
    const IconCard = swithAccordionProps?.Icon;

    const changeOpenAndCloseByIndex = () => {
        if (currentAccordion === index) {
            setValue(CommissionFormCostsAccordionMetadataKey, null);
        } else {
            setValue(CommissionFormCostsAccordionMetadataKey, index);
        }
    }

    const onClickExpand = () => {
        changeOpenAndCloseByIndex();
        if (!!useSwithExpandIcon && (nameSwithValue ?? "")?.length > 0) {
            if (currentAccordion !== index) {
                setValue(nameSwithValue!, true);
            } else {
                setValue(nameSwithValue!, false);
            };
        }
    };

    const SwithExpandIcon = () => {
        if (!nameSwithValue) return <></>
        return <Switch
            id="simple-switch-label"
            onChange={(_: any, checked: boolean) => {
                setValue(nameSwithValue, checked);
            }}
            checked={watch(nameSwithValue)}
            name={nameSwithValue}
        />
    }

    const isExpanded = expanded !== undefined ? expanded : currentAccordion === index || (index === 0 && currentAccordion === undefined);
    return (
        <CustomizedAccordion
            sx={sxAccordion}
            elevation={0}
            expanded={useSwithExpandIcon ? (!disabled && !!Boolean(watch(nameSwithValue!))) : isExpanded}
            disabled={disabled}
        >
            <CustomAccordionSummary
                useSwithExpandIcon={useSwithExpandIcon}
                expandIcon={useSwithExpandIcon ? <SwithExpandIcon /> : <ExpandMore sx={iconSx} />}
                onClick={onClickExpand}
            >
                {useSwithExpandIcon ? (
                    <Stack direction='row' alignItems='center' flexDirection='row' spacing={2}>
                        <div style={{ position: 'relative' }} >
                            <BackgroundCustom isDisable={disabled}></BackgroundCustom>
                            {!!IconCard && <IconGrid>
                                <IconCard sx={{ height: 24, width: 24 }} htmlColor={disabled ? theme.palette.grey[400] : theme.palette.primary.main} />
                            </IconGrid>}
                        </div>
                        <Typography variant={descriptionVariant} color="common.black" fontWeight={700}>
                            {description}
                        </Typography>
                    </Stack>
                ) : (
                    <Fragment>
                        {tooltipText && (
                            <Grid container alignItems="center">
                                <Typography variant={descriptionVariant} color="common.black" fontWeight={700}>
                                    {description}
                                </Typography>
                                <Tooltip description={tooltipText} title="" />
                            </Grid>
                        )}
                        {!tooltipText && (
                            <Typography variant={descriptionVariant} color="common.black" fontWeight={700}>
                                {description}
                            </Typography>
                        )}
                    </Fragment>
                )}
            </CustomAccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </CustomizedAccordion>
    );
};

export default CustomAccordionContainer;
