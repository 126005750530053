import { BankAccountView } from './BankAccountViewForm';
import {
    useAddAccountEntryMutation,
    useBankAccountBalance,
    useTerminateMutation,
} from 'contexts/bankAccount/bankAccountContext';
import { useNavigate, useParams } from 'react-router';
import Offline from 'components/Offline/Offline';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import {
    ApiResponseError,
    ToastType,
    showErrorToast,
    showSuccessToast,
    toastState,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { BankAccountActiveSkeleton } from 'components/Skeleton/BankAccountActiveSkeleton';
import { Drawer, Popup } from '@uy3/web-components';
import { useState } from 'react';
import Toast from 'components/Toast/Toast';
import TerminateForm from './Popup/TerminateAccount/Terminate';
import { FieldValues } from 'react-hook-form';
import { FormProvider } from 'contexts/formContext';
import { validationSchemaTerminateAccount } from './Popup/TerminateAccount/TerminateSchema';
import {
    AddAccountEntryContainer,
    validationSchemaAddAccountEntryForm,
    defaultValuesAddAccountEntryForm,
} from './Drawer';
import {
    defaultValuesConfirmMfaForm,
    validationConfirmMfaFormSchema,
} from 'components/ConfirmMfaForm/ConfirmMfaFormSchema';
import ConfirmMfaForm from 'components/ConfirmMfaForm/ConfirmMfaForm';
import { useGenerateSessionIdMutation } from 'contexts/identityContext';
import { IMFAState } from 'contexts/bankAccount/bankAccountType';
import { AxiosResponse } from 'axios';

export function BankAccountViewContainer() {
    const { bankAccountId } = useParams();
    const { status, data, error, refetch, isLoading } = useBankAccountBalance(bankAccountId!);
    const [openPopup, setOpenPopup] = useState(false);
    const [toast, setToast] = useState<ToastType>(toastState);
    const [currentStep, setCurrentStep] = useState<'addAccountEntry' | 'confirmationMfa'>();
    const [formFieldValues, setFormFieldValues] = useState<FieldValues>();
    const { startRequest, endRequest } = useApiRequest();
    const navigate = useNavigate();

    const openPopupTerminateAccount = () => setOpenPopup(true);
    const onClose = () => {
        setCurrentStep(undefined);
        setOpenPopup(false);
    };

    const onError = (response: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(response);
        showErrorToast('Ops, ocorreu um erro', errorMessage, setToast);
    };

    const handleOnSuccess = (isTerminate = false) => {
        if (isTerminate) {
            navigate('/contas-digitais/ativas');
        } else {
            refetch();
            onClose();
            showSuccessToast('Sucesso!', 'Operação realizada com sucesso', setToast);
        }
    };

    const { mutateAsync, isLoading: isLoadingTerminate } = useTerminateMutation(
        bankAccountId!,
        () => handleOnSuccess(true),
        onError
    );

    const handleTerminateAccount = (payload: FieldValues) => mutateAsync(payload);
    const validationSchema = validationSchemaTerminateAccount();

    const { AccountEntryMutation } = useAddAccountEntryMutation(
        bankAccountId!,
        () => handleOnSuccess(),
        onError
    );
    const { mutateGenerateSessionId } = useGenerateSessionIdMutation();

    const onSubmit = async (values: IMFAState) => {
        startRequest();
        await mutateGenerateSessionId({
            userPassword: values.password,
            then: async (response: AxiosResponse) => {
                const payload = {
                    ...formFieldValues,
                    sessionId: response.data,
                    code: values?.code,
                };

                await AccountEntryMutation(payload);
                endRequest(true);
            },
        });
    };

    const handleFormSave = (values: FieldValues) => {
        setFormFieldValues(values);
        setCurrentStep('confirmationMfa');
    };

    function mfaForm() {
        return (
            <FormProvider
                validationSchema={validationConfirmMfaFormSchema()}
                defaultValues={defaultValuesConfirmMfaForm}
                onSubmit={onSubmit}
            >
                <ConfirmMfaForm onClose={() => setCurrentStep('addAccountEntry')} />
            </FormProvider>
        );
    }

    function addAccountEntryForm() {
        return (
            <FormProvider
                validationSchema={validationSchemaAddAccountEntryForm}
                defaultValues={defaultValuesAddAccountEntryForm}
                onSubmit={handleFormSave}
            >
                <AddAccountEntryContainer onClose={onClose} />
            </FormProvider>
        );
    }

    const formDrawer: { [key: string]: React.ReactNode } = {
        addAccountEntry: addAccountEntryForm(),
        confirmationMfa: mfaForm(),
    };

    const form = formDrawer[currentStep || 'addAccountEntry'];
    const openDrawer = currentStep === 'addAccountEntry' || currentStep === 'confirmationMfa';
    const statesLoadingProgress = status === 'loading' || isLoading || isLoadingTerminate;

    if (status === 'error') {
        const { errorMessage } = mapErrorResponse(error as ApiResponseError);
        return <Offline errorMessage={errorMessage} />;
    }

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <Popup
                open={openPopup}
                title="Encerrar conta"
                text="Tem certeza que deseja encerrar a conta?"
                onClose={onClose}
                children={
                    <FormProvider
                        onSubmit={handleTerminateAccount}
                        validationSchema={validationSchema}
                        defaultValues={{}}
                    >
                        <TerminateForm onClosePopup={onClose} />
                    </FormProvider>
                }
            />

            <Drawer anchor="right" title="Adicionar Lançamento" open={openDrawer} onClose={onClose}>
                {form}
            </Drawer>

            <BankAccountActiveSkeleton isLoading={statesLoadingProgress}>
                <BankAccountView
                    bankAccountId={bankAccountId}
                    queryData={data!}
                    refresh={refetch}
                    onTerminateAccount={openPopupTerminateAccount}
                    onAddAccountEntry={() => setCurrentStep('addAccountEntry')}
                />
            </BankAccountActiveSkeleton>
        </>
    );
}
