import { isEmpty } from 'lodash';
import { NotificationLevelEnum } from 'services/notifications/enums/notificationLevelEnum';
import {
    getNotificationTypeDescription,
    NotificationTypeEnum,
} from 'services/notifications/enums/notificationTypeEnum';
import { INotificationCustomFromSchemaType } from 'services/notifications/types/notificationsCreateModel';
import {
    NotificationSettingReadModel,
    NotificationType,
} from 'services/notifications/types/notificationsReadModel';
import { array, boolean, object, string } from 'yup';

const NotificationTypes = object().shape({
    type: string()
        .typeError('Tipo: precisa ser preenchido')
        .required('Tipo: precisa ser preenchido'),
    level: string()
        .typeError('Nível: precisa ser preenchido')
        .required('Nível: precisa ser preenchido'),
    typeDisplay: string().nullable().notRequired(),
});

const ListSchema = object().shape({
    resource: string().nullable(),
    title: string().nullable(),
    fields: array().of(NotificationTypes).nullable(),
    isEnableConfiguration: boolean().nullable(),
});

export function validationSchemaSettingNotificationForm() {
    return object().shape({
        notificationTypes: array()
            .of(ListSchema)
            .min(1, 'É necessário informar ao menos uma definição de preferência.'),
    });
}

const mapperObj = (notification: NotificationType) => {
    return {
        typeDisplay: getNotificationTypeDescription(notification.type),
        type: notification.type,
        level: notification.level,
    };
};

export const defaultValuesSettingNotification = (
    notificationData: NotificationSettingReadModel
) => {
    const list = notificationData?.notificationTypes ?? [];

    // filter type includes in NotificationTypeEnum to bankslip
    const notificationListBankSlip =
        list?.filter((x) => [11, 12, 13, 14, 15, 21, 31].includes(x.type))?.map((x) => mapperObj(x)) ?? [];

    // filter type includes in NotificationTypeEnum to communication
    const notificationListCommunication =
        list?.filter((x) => x.type === 1)?.map((x) => mapperObj(x)) ?? [];

    debugger;
    return {
        notificationTypes: [
            {
                resource: 'Bankslip',
                title: 'Boletos',
                isEnableConfiguration: !notificationListBankSlip?.every((x) => x.level === 0),
                fields: !isEmpty(notificationListBankSlip) ? notificationListBankSlip : defaultListBankSlip,
            },
            {
                resource: 'Communication',
                title: 'Comunicado',
                isEnableConfiguration: !notificationListCommunication?.every((x) => x.level === 0),
                fields: !isEmpty(notificationListCommunication) ? notificationListCommunication : defaultListCommunication,
            },
            {
                resource: 'BankAccount',
                title: 'Conta digital',
                isEnableConfiguration: false,
                fields: [],
            },
            {
                resource: 'CreditNote',
                title: 'Operações de crédito',
                isEnableConfiguration: false,
                fields: [],
            },
        ] as INotificationCustomFromSchemaType[],
    };
};

const defaultListBankSlip = [
    {
        typeDisplay: getNotificationTypeDescription(NotificationTypeEnum.Billing_Cobranca_Pago),
        type: NotificationTypeEnum.Billing_Cobranca_Pago,
        level: NotificationLevelEnum.None,
    },
    {
        typeDisplay: getNotificationTypeDescription(
            NotificationTypeEnum.Billing_Cobranca_Registrado
        ),
        type: NotificationTypeEnum.Billing_Cobranca_Registrado,
        level: NotificationLevelEnum.None,
    },
    {
        typeDisplay: getNotificationTypeDescription(
            NotificationTypeEnum.Billing_Cobranca_EnviadoCartorio
        ),
        type: NotificationTypeEnum.Billing_Cobranca_EnviadoCartorio,
        level: NotificationLevelEnum.None,
    },
    {
        typeDisplay: getNotificationTypeDescription(
            NotificationTypeEnum.Billing_Cobranca_Protestado
        ),
        type: NotificationTypeEnum.Billing_Cobranca_Protestado,
        level: NotificationLevelEnum.None,
    },
];

const defaultListCommunication = [
    {
        typeDisplay: getNotificationTypeDescription(NotificationTypeEnum.Notice),
        type: NotificationTypeEnum.Notice,
        level: NotificationLevelEnum.None,
    },
];
