import {
    checkAverbationCreditNoteFGTS,
    editOrCreateCreditNote,
    getCreditNoteById,
    getCreditNoteList,
    installmentCreditNoteById,
    postTransferReceiptPdf,
    putFinishedManualWarranty,
} from 'services/creditNote';
import {
    ApiResponseError,
    useApiRequest,
    GetViewApiResponse,
    GetViewApiResponseSuccess,
    GetListApiResponse,
    GetListApiResponseSuccess,
} from '../apiRequestContext';
import { useIdentity } from '../identityContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, isAxiosError } from 'axios';
import {
    AmortizationReadModel,
    ComplianceDisapproveReasonType,
    CreditNoteReadModel,
    CreditNoteProps,
    TransferReceiptPdfType,
    InstallmentCreditNoteType,
} from 'services/creditNote/types/creditNoteReadModel';
import { FieldValues } from 'react-hook-form';
import { PaymentScheduleItemReadModel } from 'services/creditNote/types/creditNoteReadModel';
import { useTenant } from 'contexts/tenantContext';
import { useFilterValues } from 'contexts/filterValuesContext';
import { useUserPermissionData } from 'contexts/userContext';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router';
import { getRejectionReason } from 'services/RejectionReason/rejectionReason';
import { SelectOption } from 'components/Forms/FormFields';
import { useState } from 'react';
import { createSimulationCreditNote } from 'services/Amortization';
import { handleErrorUseQuery } from 'helpers/methods/handleErrorUseQuery';
import { CheckAverbationResponseType } from 'services/creditNote/types/genericTypes';

export function useCreditData(id: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('CreditNote', 'Read');

    const creditNoteId = isEmpty(id) ? 'nova' : id;
    const { status, data, error, isFetching, refetch, isLoading } = useQuery({
        enabled: !!token && !!creditNoteId && creditNoteId !== 'nova' && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['credit-data', creditNoteId],
        queryFn: async (): Promise<GetViewApiResponse<CreditNoteReadModel>> => {
            startRequest();
            if (creditNoteId === 'nova') {
                endRequest(true);
                return { data: {} } as GetViewApiResponseSuccess<CreditNoteReadModel>;
            }
            const { data, status, statusText } = await getCreditNoteById(id, token);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }
            return data as GetViewApiResponseSuccess<CreditNoteReadModel>;

        },
    });

    return {
        creditStatus: status,
        creditData: data as GetViewApiResponseSuccess<CreditNoteReadModel>,
        creditError: error,
        isFetching,
        refetch,
        isLoading
    };
}

export function useGetRejectionReason() {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { status, data, error, isFetching, refetch } = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-compliance-disapprove-reason'],
        queryFn: async () => {
            startRequest();
            const { data, status, statusText } = await getRejectionReason(token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            const query = data as ComplianceDisapproveReasonType[];

            const queryMappedList = query.map(option => {
                return {
                    label: option.description,
                    value: option.code
                }
            }) ?? [];
            return queryMappedList as unknown as SelectOption[];

        },
    });

    return {
        status,
        listReasons: data ?? [] as SelectOption[],
        error,
        isFetching,
        refetch
    };
}

export function useCreditNoteMutation(
    id: string,
    onSuccess?: (data: any) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { currentTenantId } = useTenant();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationKey: ['credit-note-mutation', id],
        mutationFn: async (
            values: CreditNoteReadModel
        ) => {
            setSubmitError(undefined);
            startRequest();
            const resp = await editOrCreateCreditNote(values, id, token!, currentTenantId!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            return data;
        },
        onSuccess: (data) => {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, context) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: "error", code: axErr.code!, errors: [] }
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });

    return { mutate, isLoading };
}

export function useCreditList(filters: CreditNoteProps) {
    const { token } = useIdentity();
    const { pathname } = useLocation();
    const { currentTenantId } = useTenant();
    const isNaturalAndLegalPerson =
        pathname.includes('pessoas-fisicas') || pathname.includes('pessoas-jurídica');
    const { filterValues } = useFilterValues();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const recordType = isEmpty(filterValues.recordType) ? 'CreditNote' : filterValues.recordType;
    const listDataFilters = recordType === 'CreditNote' && !isNaturalAndLegalPerson && { ...filterValues.filters };
    let filtersComplete = { tenant: currentTenantId, ...filters, ...listDataFilters };
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('CreditNote', 'Read');

    const relatedPersons = filterValues.filters?.relatedPersons as string[];
    const typeOfRelationship = filterValues.filters?.typeOfRelationship as string[];

    if (relatedPersons?.length > 0 && typeOfRelationship?.length > 0) {
        filtersComplete = {
            ...filtersComplete,
            relatedPersons: relatedPersons.map((item, indice) => {
                return `${item}:${typeOfRelationship[indice]}`
            })
        };
        //@ts-ignore
        delete filtersComplete.typeOfRelationship;
    }

    const { status, data, error, isFetching, refetch, isLoading } = useQuery({
        enabled: !!token && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['credit-note-list', filtersComplete, currentTenantId, filters.personId],
        queryFn: async (): Promise<GetListApiResponse<CreditNoteReadModel>> => {
            startRequest();
            const resp = await getCreditNoteList(filtersComplete, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }
            return data as GetListApiResponseSuccess<CreditNoteReadModel>;
        },
    });

    return { creditStatus: status, creditData: data, error, creditFetch: isFetching, refetch, isLoading };
}


export function useCreditNoteAutocomplete() {
    const { token } = useIdentity();
    const { filterValues, setFilterValues } = useFilterValues();
    const { currentTenantId } = useTenant();
    const { startRequest, endRequest } = useApiRequest();
    const [creditNoteList, setCreditNoteList] = useState<{ label: string, value: string }[]>([])

    let filtersComplete = { tenant: currentTenantId, page: 0, size: 10, ...filterValues.filters };

    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('CreditNote', 'Read');

    const queryContext = useQuery({
        enabled: !!token && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        queryKey: ['credit-note-list-autocomplete', filtersComplete, currentTenantId],
        queryFn: async (): Promise<GetListApiResponse<CreditNoteReadModel>> => {
            startRequest();
            const resp = await getCreditNoteList(filtersComplete, token!);
            const { data } = resp;
            endRequest(true);

            const dataSuccess = data as GetListApiResponseSuccess<CreditNoteReadModel>;
            const options = dataSuccess.data.map((item) => (
                {
                    label: `${item.creditNoteNo} - ${item.personDisplay} (${item.statusDisplay})`,
                    value: item.id
                }));

            setCreditNoteList(options);

            return dataSuccess;
        },
    });

    const creditNoteAtucomplete = {
        listOptions: creditNoteList,
        loading: !hasReadPermission ? false : queryContext.isLoading || queryContext.isFetching,
        onSearch: (searchString: string | undefined) => searchString && setFilterValues({ ...filterValues.filters, searchString }, 'creditnote')
    };

    return { ...queryContext, creditNoteAtucomplete };
}


export function useCreateSimulation(
    onSuccess?: (data: any) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (values: FieldValues): Promise<GetViewApiResponse<AmortizationReadModel>> => {
            setSubmitError(undefined);
            startRequest();
            const resp = await createSimulationCreditNote(values, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            return data as GetViewApiResponse<AmortizationReadModel & PaymentScheduleItemReadModel>;
        },
        onSuccess: (data) => {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, context) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: "error", code: axErr.code!, errors: [] }
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });

    return { mutate, isLoading };
}
export function useTransferReceiptPdfMutation(
    creditNoteId: string,
    onSuccess?: (data: any) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (values: TransferReceiptPdfType): Promise<GetViewApiResponse<AmortizationReadModel>> => {
            setSubmitError(undefined);
            startRequest();
            const resp = await postTransferReceiptPdf(creditNoteId, values, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            return data as GetViewApiResponse<AmortizationReadModel & PaymentScheduleItemReadModel>;
        },
        onSuccess: onSuccess,
        onError: onError,
    });

    return { mutate, isLoading };
}

export function useFinishManualWarrantyMutation(
    creditNoteId: string,
    onSuccess?: (data: any) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (values: FieldValues | null) => {
            setSubmitError(undefined);
            startRequest();
            const payload = values === null ? [] : values
            const resp = await putFinishedManualWarranty(creditNoteId, payload, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            return data as GetViewApiResponse<AmortizationReadModel & PaymentScheduleItemReadModel>;
        },
        onSuccess: onSuccess,
        onError: (error: ApiResponseError) => {
            endRequest(false);
            setSubmitError(error);
            onError && onError(error);
        },
    });

    return { mutate, isLoading };
}

export function useCreateInstallmentMutate(
    creditNoteId: string, 
    onSuccess: (data: unknown) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (values: InstallmentCreditNoteType | null) => {
            startRequest();
            const payload = values !== null ? values :{}
            const { data } = await installmentCreditNoteById(creditNoteId, payload, token!);
            endRequest(true);
            endRequest(true);
            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}

export function useCheckAverbationCreditNoteMutation(
    creditNoteId: string, 
    onSuccess: (data: CheckAverbationResponseType) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async () => {
            startRequest();
            const { data } = await checkAverbationCreditNoteFGTS(creditNoteId, token!);
            endRequest(true);
            endRequest(true);
            return data as CheckAverbationResponseType;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}
