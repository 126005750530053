import { useContext, useEffect, useState } from 'react';
import { FormProvider, useFormContext } from '../formContext';
import * as creditNoteAPI from 'services/creditNote';
import { Popup, Button, Modal, Drawer } from '@uy3/web-components';
import { Grid, Typography } from '@mui/material';
import { useIdentity } from '../identityContext';
import { useNavigate } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import {
    ApiResponseError,
    ToastType,
    UploadGet,
    invalidateAndRefetch,
    showErrorToast,
    showInfoToast,
    showSuccessToast,
    showWarningToast,
    toastState,
    useApiRequest,
} from '../apiRequestContext';
import { useTenant } from '../tenantContext';
import { useCreditData } from './creditContext';
import { CancelFormContainer } from 'pages/CreditNote/CreditNoteForm/Modals/Cancel/CancelFormContainer';
import { ApproveCreditNote } from 'pages/CreditNote/CreditNoteForm/Modals/ApproveAndReject/ApproveForm';
import { RejectCreditNote } from 'pages/CreditNote/CreditNoteForm/Modals/ApproveAndReject/RejectFormContainer';
import { RecoverSimulationCreditNote } from 'pages/CreditNote/CreditNoteForm/Modals/RecoverSimulation/RecoverSimulationForm';
import { SendDocumentsCreditNote } from 'pages/CreditNote/CreditNoteForm/Modals/SendDocs/SendDocsForm';
import AssignDrawer from 'components/Assign/AssignDrawer';
import { RefreshProgress } from 'components/RefreshProgress';
import ApproveLiquidationContainer from 'pages/CreditNote/CreditNoteForm/Modals/ApproveLiquidation/ApproveLiquidationContainer';
import { GeneratePreviewCreditNote } from 'pages/CreditNote/CreditNoteForm/Modals/GeneratePreview/GeneratePreviewForm';
import { ManualUpdate } from 'pages/CreditNote/CreditNoteForm/Modals/ManualUpdate/ManualUpdate';
import {
    useGetAvailableReserveTokenByPersonId,
    useNaturalPersonData,
} from 'contexts/naturalPersonContext';
import { useLegalPersonData } from 'contexts/legalPersonContext';
import {
    CreditNoteActionsProps,
    CreditNoteActionsProviderProps,
    DrawerState,
    ModalState,
    PopupState,
} from './creditNoteActions.interface';
import { PaymentRevisionFormContainer } from 'pages/CreditNote/CreditNoteForm/Modals/PaymentRevision/PaymentRevisionFormContainer';
import { DeleteCheckListContainer } from 'pages/CreditNote/CreditNoteForm/Modals/DeleteCheckList/DeleteCheckListContainer';
import { TransferReceiptPdfFormContainer } from 'pages/CreditNote/CreditNoteForm/Modals/TransferReceiptPdf/TransferReceiptPdfFormContainer';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { useFilterValues } from 'contexts/filterValuesContext';
import Toast from 'components/Toast/Toast';
import { objectToRedirectWithSearchParams } from 'helpers/formats/StringFormats';
import {
    ApproveCreditNoteByIdProps,
    GeneratePaymentLinkProps
} from 'services/creditNote/types/creditNoteReadModel';
import ApproveSignature from 'pages/CreditNote/CreditNoteForm/Modals/ApproveSignature/ApproveSignature';
import {
    defaultValuesRejectSignatureForm,
    validationSchemaRejectSignatureForm,
} from 'pages/CreditNote/CreditNoteForm/Modals/ApproveSignature/ApproveSignatureSchema';
import { FieldValues } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { CompleteManualWarrantyContainer } from 'pages/CreditNote/CreditNoteForm/Modals/CompleteManualWarranty/CompleteManualWarrantyContainer';
import { DisapproveMarginReverseForm } from 'pages/CreditNote/CreditNoteForm/Modals/DisapproveMarginReverse/DisapproveMarginReverseForm';
import { ReserveTokenContainer } from 'components/ReserveToken/ReserveTokenContainer';
import { ShowTokensRegistered } from 'pages/CreditNote/CreditNoteForm/Modals/ShowTokensRegistered/ShowTokensRegistered';
import { ApproveMarginReverseContainer } from 'pages/CreditNote/CreditNoteForm/Modals/ApproveMarginReverse/ApproveMarginReverseContainer';
import { ReprocessByStatusContainer } from 'pages/CreditNote/CreditNoteForm/Modals/ReprocessByStatus/ReprocessByStatusContainer';
import FraudRecordContainer, { FraudRecord } from 'components/GenericForms/FraudRecord/FraudRecordContainer';
import { useFraudRecordMutation } from 'contexts/fraudRecordContext';
import { isDateInThePast } from 'helpers/methods/IsDateInThePast';
import { ChangeStartDateContainer, ChangeStartDateContainerProps } from 'pages/CreditNote/CreditNoteForm/Modals/ChangeStartDate/ChangeStartDateContainer';
import moment from 'moment';
import { useQueryCacheContext } from 'contexts/queryCacheContext';
import { useAppConfig } from 'contexts/appConfig';
import { CreditNoteActionsContext } from './createContext';
import { SiapeQueryFormContainer } from 'components/GenericForms/SiapeQueryForm/SiapeQueryFormContainer';
import { createSimulationCreditNote, QueryAmortizationCreateModel } from 'services/Amortization';
import { InstallmentCreditNoteContainer } from 'pages/CreditNote/CreditNoteForm/Modals/InstallmentCreditNote/InstallmentCreditNoteContainer';
import { CheckAverbation } from 'pages/CreditNote/CreditNoteForm/Modals/CheckAverbation/CheckAverbation';

type GenericChildrenActionProps = {
    onClick: () => void;
};

export const CreditNoteActionsProvider = ({
    children,
    isNewCreditNote,
    id,
    creditNoteRefetch,
    product
}: CreditNoteActionsProviderProps) => {
    const { watch, getValues, setValue, trigger } = useFormContext();
    const { startRequest, endRequest, submitting } = useApiRequest();
    const { appConfig } = useAppConfig();
    const { creditData } = useCreditData(id!);
    const { getQueryCache } = useQueryCacheContext();
    const navigate = useNavigate();
    const { filterValues } = useFilterValues();
    const queryClient = useQueryClient();
    const { token } = useIdentity();
    const [toast, setToast] = useState<ToastType>(toastState);
    const [popupProps, setPopupProps] = useState<PopupState | undefined>(undefined);
    const [modalProps, setModalProps] = useState<ModalState | undefined>(undefined);
    const [drawerProps, setDrawerProps] = useState<DrawerState | undefined>(undefined);
    const [actionGeneric, setActionGeneric] = useState<string | undefined>(undefined);
    const { currentTenantId } = useTenant();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [personData, setPersonData] = useState<any>();
    const discriminator = getValues('personDiscriminator');
    const personId = creditData?.personId;
    const isNaturalPerson = discriminator === 'NaturalPerson';
    const naturalPersonId = isNaturalPerson ? personId : '';
    const leaglPersonId = discriminator === 'LegalPerson' ? personId : '';
    const { naturalPersonData } = useNaturalPersonData(naturalPersonId);
    const { legalPersonData } = useLegalPersonData(leaglPersonId);
    const queryDataByKey = getQueryCache(['credit-data', id]);
    const { data: tokensList } = useGetAvailableReserveTokenByPersonId(
        personId!,
        isNaturalPerson,
        actionGeneric
    );

    const updateStartDateBehaviourValue = appConfig.UPDATE_START_DATE_BEHAVIOUR;

    useEffect(() => {
        if (discriminator === 'LegalPerson') {
            setPersonData(legalPersonData);
        } else if (discriminator === 'NaturalPerson') {
            setPersonData(naturalPersonData);
        }
    }, [discriminator, legalPersonData, naturalPersonData]);

    const navigateWithFilters = (path: string) => {
        const filter = filterValues.filters;
        if (Object.keys(filter ?? {}).length > 0) {
            const url = `${path}${objectToRedirectWithSearchParams(filter)}`;
            return navigate(url);
        }
        return navigate(path);
    };

    let uploads = creditData?.uploads ? creditData?.uploads : watch('uploads') ?? [];

    const hasDraftDoc: UploadGet = uploads?.find(
        (file: UploadGet) =>
            file.fileType === 'Draft' &&
            (file.displayName?.includes('.pdf') || file.displayName?.includes('.docx'))
    );

    const hasAssignmentDoc: UploadGet = uploads?.find(
        (file: UploadGet) =>
            file.fileType === 'Assignment' &&
            (file.displayName?.includes('.pdf') || file.displayName?.includes('.docx'))
    );

    const handleErrorResponse = (response: ApiResponseError) => {
        const { errorMessage, warningToastError } = mapErrorResponse(response);

        if (warningToastError && warningToastError?.length > 0) {
            const title = 'Atenção!';
            showWarningToast(title, warningToastError, setToast);
            endRequest(false);
        } else {
            const title = 'Ops, ocorreu um erro!';
            const description = errorMessage;
            showErrorToast(title, description, setToast);
            endRequest(false);
        }
    };

    const handleDelete = () => {
        startRequest();
        creditNoteAPI
            .deleteCreditNoteById(id!, token!)
            .then(async () => {
                await queryClient.invalidateQueries(['credit-note-list']);
                await queryClient.refetchQueries(['credit-note-list']);
                endRequest(true);
                navigateWithFilters('/ccb/operacoes');
            })
            .catch((response: ApiResponseError) => handleErrorResponse(response));
    };

    const handleApproveLiquidation = async (payload: ApproveCreditNoteByIdProps) => {
        startRequest();
        await creditNoteAPI
            .approveCreditNoteById(id!, token!, watch('status'), payload)
            .then(async () => {
                await queryClient.invalidateQueries(['credit-note-list']);
                await queryClient.refetchQueries(['credit-note-list']);
                showSuccessToast(
                    'Liquidação aprovada com sucesso.',
                    'Ótimo! Agora você seguir para a próxima etapa.',
                    setToast
                );
                navigateWithFilters('/ccb/operacoes');
                endRequest(true);
            })
            .catch((response: ApiResponseError) => handleErrorResponse(response));
    };

    const handleSignature = async (
        reject: boolean,
        type: 'approveSignature' | 'signature',
        formValues?: FieldValues
    ) => {
        startRequest();
        let message = getValues('message') ?? formValues?.message;
        let payload = {
            id: id!,
            token: token!,
            reject,
            message,
            type,
        } as creditNoteAPI.SignatureCreditNoteProps;

        if (isEmpty(payload.message)) {
            delete payload.message;
        }

        creditNoteAPI
            .signatureCreditNoteById(payload)
            .then(async () => {
                await queryClient.invalidateQueries(['credit-note-list']);
                await queryClient.refetchQueries(['credit-note-list']);
                navigateWithFilters('/ccb/operacoes');
                endRequest(true);
            })
            .catch((response: ApiResponseError) => handleErrorResponse(response));
    };

    const handleUpdateDocs = async () => {
        startRequest();
        var file = watch('uploads') ?? [];
        await creditNoteAPI
            .updateFiles(file, id!, token!)
            .then(() => {
                queryClient.invalidateQueries({
                    queryKey: ['credit-data', id!],
                    refetchType: 'active',
                });
                endRequest(true);
                showSuccessToast(
                    'Documentos atualizados com sucesso.',
                    'Ótimo! Agora você pode visualizar os documentos atualizados.',
                    setToast
                );
            })
            .catch((response: ApiResponseError) => handleErrorResponse(response));
    };

    const handleReprocessByStatus = async (creditNoteSequence: string) => {
        startRequest();
        await creditNoteAPI
            .postReprocessByStatus(creditNoteSequence, [id!], token!)
            .then(() => {
                queryClient.invalidateQueries({
                    queryKey: ['credit-data', id!],
                    refetchType: 'active',
                });
                endRequest(true);
                showSuccessToast('Esteira reiniciada com sucesso', '', setToast);
                setModalProps(undefined);
            })
            .catch((response: ApiResponseError) => handleErrorResponse(response));
    };

    const handleApproveOrDisapproveMarginReserve = async (
        type: 'approve' | 'disapprove',
        values: FieldValues
    ) => {
        const optionsRequest: { [type: string]: () => void } = {
            approve: async () => {
                startRequest();

                await creditNoteAPI
                    .postApproveMarginReserve(id!, token!, values)
                    .then(async () => {
                        await invalidateAndRefetch(queryClient, ['credit-data', id!]);
                        await invalidateAndRefetch(queryClient, ['credit-data', id!]);

                        endRequest(true);
                        showSuccessToast('Reserva de margem aprovada com sucesso', '', setToast);
                        setDrawerProps(undefined);
                    })
                    .catch((response: ApiResponseError) => handleErrorResponse(response));
            },
            disapprove: async () => {
                startRequest();
                await creditNoteAPI
                    .postDisapproveMarginReserve(id!, values!, token!)
                    .then(async () => {
                        await queryClient.invalidateQueries(['credit-data', id!]);
                        await queryClient.refetchQueries(['credit-data', id!]);
                        endRequest(true);
                        showSuccessToast('Reserva de margem rejeitada com sucesso', '', setToast);
                        setDrawerProps(undefined);
                    })
                    .catch((response: ApiResponseError) => handleErrorResponse(response));
            },
        };
        return optionsRequest[type!]();
    };

    const GeneratePaymentLinkChildren = ({ paymentLink }: { paymentLink: string }) => {
        return (
            <>
                <Typography variant="h5" mt={0.75} color="darkgray">
                    {paymentLink}
                </Typography>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setPopupProps(undefined)}
                        size="medium"
                        sx={{ mt: 3, mr: 2 }}
                    >
                        Fechar
                    </Button>
                </Grid>
            </>
        );
    };

    const handleGeneratePaymentLink = async () => {
        startRequest();
        let valueTotalInCents = creditData?.paymentScheduleItems
            ?.map((value) => value.payment)
            .reduce((acc, current) => acc + current);

        let dataGeneragePaymentLink: GeneratePaymentLinkProps = {
            redistrationNumber: personData?.registrationNumber,
            customerName: personData?.name,
            customerCellphone: personData?.phone,
            customerEmail: personData?.email,
            totalValueInCents: valueTotalInCents,
            numberOfPayments: getValues('amortization.termInMonths'),
            description: getValues('creditNoteNo'),
        };

        await creditNoteAPI
            .generatePaymentLink(dataGeneragePaymentLink, token!)
            .then((response: any) => {
                queryClient.invalidateQueries({
                    queryKey: ['credit-note-list'],
                    refetchType: 'active',
                });
                setPopupProps({
                    title: 'Link gerado com sucesso!',
                    message: 'Copie o link de pagamento abaixo para sua área de transferência.',
                    children: <GeneratePaymentLinkChildren paymentLink={response?.data ?? ''} />,
                });
                const title = 'Link de pagamento gerado com sucesso!';
                const description = undefined;
                showSuccessToast(title, description, setToast);
                endRequest(true);
            })
            .catch((response: ApiResponseError) => handleErrorResponse(response));
    };

    const renderModalToUpdateStartDate = (props: ChangeStartDateContainerProps) => {
        const optionsTexts: { [type: string]: { title: string, description: string } } = {
            "submitApproval": {
                title: 'Atualização de plano de pagamento',
                description: `O plano de pagamento esta calculado para a data de ${moment(watch('amortization.startDate')).format('L')}. Deseja atualizar o plano de pagamentos?`
            },
            "simulate": {
                title: 'Atualizar data de início',
                description: `A data de início da operação está no passado. Deseja atualizar para a data de hoje (${moment().format('L')}) ?`
            },
        }
        return setModalProps({
            title: optionsTexts[props?.recordType]?.title,
            description: optionsTexts[props?.recordType]?.description,
            children: <ChangeStartDateContainer {...props} />
        })
    }

    const updateCreditNoteByIdAsync = async (onSuccess?: () => void) => {
        let payload = getValues();
        const isCustomPaymentPlan = payload?.customPaymentPlan;
        if (!isCustomPaymentPlan) {
            payload.paymentScheduleItems = [];
        }
        return await creditNoteAPI.putCreateCreditNoteById(id!, payload, token!, currentTenantId)
            .then(() => onSuccess && onSuccess())
            .catch((response: ApiResponseError) => {
                handleErrorResponse(response)
                setValue('amortization.startDate', queryDataByKey?.amortization?.startDate);
            });
    }

    const setNewStartDate = async () => {
        await setValue('amortization.startDate', new Date(new Date().setHours(0, 0, 0, 0)).toISOString());
    }

    const simulateCreditNoteAsync = async (payload: QueryAmortizationCreateModel, handleSuccess?: (data: any) => void) => {
        startRequest();
        await createSimulationCreditNote(payload, token!)
            .then(({ data }) => {
                setValue('createSimulation', data);
                endRequest(true);
                const title = 'Simulação realizada com sucesso.';
                const description = 'Ótimo! Agora você pode visualizar a simulação.';
                showSuccessToast(title, description, setToast);
                setModalProps(undefined);
                handleSuccess && handleSuccess(data);
            })
            .catch((response: ApiResponseError) => handleErrorResponse(response));
    }

    const handleSimulateCreditNoteAsync = async () => {

        const valid = await trigger();
        if (!valid) return valid;
        const [amortization, productId, personId, personDiscriminator] = watch(['amortization', 'productId', 'personId', 'personDiscriminator']);


        const legalPerson = personDiscriminator === 'LegalPerson';

        const handleSimulate = async (onSuccees?: (data: any) => void) => await simulateCreditNoteAsync({ amortization, productId, personId, legalPerson }, onSuccees);

        const handleSimulateAndUpdateStartDate = async () => {
            await setNewStartDate();

            return await handleSimulate(async (values) => {
                await setValue('paymentScheduleItems', values.paymentScheduleItems);
                if (!isNewCreditNote) await updateCreditNoteByIdAsync();
            });
        }

        if (!isEmpty(updateStartDateBehaviourValue)) {
            const optionsFunction: { [type: string]: () => void } = {
                'DONT_UPDATE': async () => await handleSimulate(),
                'AUTO_UPDATE': async () => await handleSimulateAndUpdateStartDate(),
            }
            return optionsFunction[updateStartDateBehaviourValue]();
        }

        const checkDateIsPast = isDateInThePast(new Date(amortization?.startDate));
        if (checkDateIsPast) {
            return renderModalToUpdateStartDate({
                handleKeepCurrentState: async () => await handleSimulate(),
                handleSubmit: handleSimulateAndUpdateStartDate,
                textCurrentStateButton: "Manter e simular",
                textHandleSubmitButton: "Atualizar e simular",
                recordType: 'simulate'
            });
        }

        return await handleSimulate();
    }

    const navigateAndEndRequest = () => {
        navigateWithFilters('/ccb/operacoes');
        endRequest(true);
    };

    const submitApproval = async (updateStartDate?: boolean) => {
        startRequest();
        return await creditNoteAPI
            .submitApprovalCreditNoteById(id!, token!, updateStartDate)
            .then(async () => {
                await queryClient.invalidateQueries({
                    queryKey: ['credit-note-list'],
                    refetchType: 'active',
                });
                return navigateAndEndRequest();
            })
            .catch((response: ApiResponseError) => {
                handleErrorResponse(response);
            });
    }

    const handleSubmitApprovalCreditNoteAsync = async () => {
        const amortization = watch('amortization');

        const checkDateIsPast = isDateInThePast(new Date(amortization?.startDate));

        const handleOnSucess = async () => {
            const title = 'Plano de pagamentos atualizado com sucesso';
            const description = 'Aguarde, estamos enviando a operação para aprovação';
            showInfoToast(title, description, setToast);
            setTimeout(async () => await submitApproval(), 2000);
        }

        const handleSubmitApprovalAndUpdateStartDate = async () => {
            startRequest()
            await setNewStartDate();
            if (!isNewCreditNote) await updateCreditNoteByIdAsync(handleOnSucess);
        }

        if (!isEmpty(updateStartDateBehaviourValue)) {
            const optionsFunction: { [type: string]: () => void } = {
                'DONT_UPDATE': async () => await submitApproval(true),
                'AUTO_UPDATE': async () => await handleSubmitApprovalAndUpdateStartDate(),
            }
            return optionsFunction[updateStartDateBehaviourValue]();
        }

        if (checkDateIsPast) {
            return renderModalToUpdateStartDate({
                recordType: 'submitApproval',
                handleKeepCurrentState: async () => await submitApproval(true),
                handleSubmit: handleSubmitApprovalAndUpdateStartDate,
                textCurrentStateButton: 'Manter proposta atual',
                textHandleSubmitButton: 'Atualizar proposta'
            });
        };

        return await submitApproval();
    }

    const GenericChildrenAction = ({ onClick }: GenericChildrenActionProps) => {
        return (
            <Grid container direction="row" pt={3} justifyContent="flex-end" alignItems="center">
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        setPopupProps(undefined);
                        setModalProps(undefined);
                    }}
                    size="medium"
                    sx={{ mt: 3, mr: 2 }}
                >
                    Não, cancelar
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClick}
                    size="medium"
                    sx={{ mt: 3 }}
                >
                    Sim, confirmar
                </Button>
            </Grid>
        );
    };

    const ComponentReserveTokenContainer = () => {
        return (
            <ReserveTokenContainer
                {...{
                    onClose: () => {
                        if (actionGeneric) {
                            return setActionGeneric(undefined);
                        }
                        setDrawerProps(undefined);
                    },
                    personId: personId!,
                    setToast,
                }}
            />
        );
    };

    const handleApproveMargimWithValues = (values: FieldValues) => {
        handleApproveOrDisapproveMarginReserve('approve', values);
    };

    const handleOnClose = () => {
        setOpenDrawer(false);
        setModalProps(undefined);
        setDrawerProps(undefined);
        setPopupProps(undefined);
        setActionGeneric(undefined);
    };

    const onSuccessFraudRecord = async () => {
        await invalidateAndRefetch(queryClient, ['credit-data', id!]);
        const title = 'Fraude registrada com sucesso!';
        const desc = 'Ótimo! Agora você pode visualizar a fraude registrada.';
        showSuccessToast(title, desc, setToast);
        handleOnClose();
    };

    const { mutateAsync } = useFraudRecordMutation(
        personId!,
        onSuccessFraudRecord,
        handleErrorResponse
    );
    const handleOnFraud = (values: FieldValues) => mutateAsync(values);

    const fraudRecordDetails: FraudRecord = {
        name: personData?.name,
        registrationNumber: personData?.registrationNumber,
        taxPayer: isNaturalPerson ? 'PF' : 'PJ',
    };


    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <RefreshProgress refreshing={submitting} />
            <AssignDrawer
                recordId={id!}
                recordType="CreditNote"
                openDrawer={openDrawer}
                onClose={handleOnClose}
                navigate="/ccb/operacoes"
            />

            <Modal
                title={modalProps?.title}
                description={modalProps?.description}
                open={!!modalProps}
                onClose={handleOnClose}
                children={modalProps?.children}
                sizeModal="large"
            />

            <Drawer
                anchor="right"
                title={drawerProps?.title}
                description={drawerProps?.description}
                open={!!drawerProps}
                onClose={handleOnClose}
                children={drawerProps?.children}
            />

            <Popup
                open={!!popupProps}
                title={popupProps?.title}
                text={popupProps?.message}
                onClose={handleOnClose}
                children={popupProps?.children}
            />

            <Drawer
                anchor="right"
                title="Adicionar token"
                description={''}
                toggleDrawer
                open={['add-tokens'].includes(actionGeneric!)}
                onClose={() => {
                    if (actionGeneric) {
                        return setActionGeneric(undefined)
                    }
                    handleOnClose();
                }}
                children={<ComponentReserveTokenContainer />}
            />
            <CreditNoteActionsContext.Provider
                value={{
                    checkAverbation: () => {
                        setModalProps({
                            title: 'Verificar averbação',
                            description: "Após a verificação, será possível conferir as informações.",
                            children: <CheckAverbation {...{
                                onClose: handleOnClose,
                                setToast
                            }}/>
                        })
                    },
                    installmentCreditNote: () => {
                        setDrawerProps({
                            title: 'Gerar cobrança',
                            description: "",
                            children: <InstallmentCreditNoteContainer {...{
                                onClose: handleOnClose,
                                setToast,
                                creditNoteId: id!
                            }} />
                        });
                    },
                    handleSiapeMarginQuery: () => {
                        setDrawerProps({
                            title: 'Consultar margem',
                            description: "",
                            children: (
                                <SiapeQueryFormContainer onClose={handleOnClose} setToast={setToast} />
                            ),
                        });
                    },
                    fraudRecord: () => {
                        setDrawerProps({
                            title: 'Registrar Fraude',
                            description: 'Antes de registar uma fraude, informe o motivo.',
                            children: (
                                <FraudRecordContainer
                                    onClose={handleOnClose}
                                    onFraudRecordSubmit={handleOnFraud}
                                    fraudRecordDetails={fraudRecordDetails}
                                />
                            ),
                        });
                    },
                    reprocessByStatus: () => {
                        setModalProps({
                            title: 'Reiniciar Esteira',
                            description: 'Selecione uma etapa para reiniciar esteira',
                            children: (
                                <ReprocessByStatusContainer
                                    handleSubmit={handleReprocessByStatus}
                                    onClose={handleOnClose}
                                />
                            ),
                        });
                    },
                    reserveToken: () => {
                        setDrawerProps({
                            title: 'Adicionar token consignado',
                            description: '',
                            children: <ComponentReserveTokenContainer />,
                        });
                    },
                    showTokensRegistered: () => {
                        setDrawerProps({
                            title: 'Tokens',
                            description: 'Lista de tokens cadastrados',
                            children: (
                                <ShowTokensRegistered
                                    onClose={handleOnClose}
                                    tokensList={tokensList!}
                                />
                            ),
                        });
                    },
                    approveMarginReserve: () => {
                        setDrawerProps({
                            title: 'Aprovar reserva de margem',
                            description: '',
                            children: (
                                <ApproveMarginReverseContainer
                                    handleAddToken={() => setActionGeneric('add-tokens')}
                                    actionGeneric={actionGeneric!}
                                    personId={personId}
                                    handleSubmit={handleApproveMargimWithValues}
                                    onClose={handleOnClose}
                                    isNaturalPerson={isNaturalPerson}
                                />
                            ),
                        });
                    },
                    disapproveMarginReserve: () => {
                        setDrawerProps({
                            title: 'Rejeitar reserva de margem',
                            description: 'Tem certeza que rejeitar reserva de margem?',
                            children: (
                                <DisapproveMarginReverseForm
                                    handleSubmit={(values: FieldValues) =>
                                        handleApproveOrDisapproveMarginReserve('disapprove', values)
                                    }
                                    onClose={handleOnClose}
                                />
                            ),
                        });
                    },
                    manualWarrantyMutate: () => {
                        const isNotRequiredFields =
                            product?.warrantyRegistrationOffice?.toLocaleLowerCase() === 'none' && product?.manualWarranty === true;

                        setDrawerProps({
                            title: 'Concluir garantia',
                            description: isNotRequiredFields ? 'Tem certeza que deseja concluir garantia?' : '',
                            children: (
                                <CompleteManualWarrantyContainer
                                    handleAddToken={() => setActionGeneric('add-tokens')}
                                    personId={personId}
                                    onClose={handleOnClose}
                                    setToast={setToast}
                                    isNaturalPerson={isNaturalPerson}
                                    isNotRequiredFields={isNotRequiredFields}
                                />
                            ),
                        });
                    },
                    deleteCheckList: () =>
                        setModalProps({
                            title: 'Remover restrições',
                            description: 'Escolha quais restrições deseja remover desse registro',
                            children: (
                                <DeleteCheckListContainer
                                    onClose={handleOnClose}
                                    personId={personId}
                                />
                            ),
                        }),
                    assign: (bol) => {
                        setOpenDrawer(bol);
                    },
                    isNewCreditNote,
                    status: creditData?.status ?? '',
                    uploads: uploads,
                    assignmentPreview: !hasAssignmentDoc
                        ? {
                            onClick: () => {
                                setPopupProps({
                                    title: 'Gerar contrato de cessão',
                                    message:
                                        "Você pode escolher entre um contrato de cessão com ou sem marca d'água. Fique à vontade para selecionar a opção que melhor atende às suas necessidades. Confira as opções abaixo.",
                                    children: (
                                        <GeneratePreviewCreditNote
                                            setPopupProps={setPopupProps}
                                            isAssignmentPreview={true}
                                        />
                                    ),
                                });
                            },
                        }
                        : {
                            href: hasAssignmentDoc?.tempUrl,
                            target: '_blank',
                        },
                    draftPreview: !hasDraftDoc
                        ? {
                            onClick: () =>
                                setPopupProps({
                                    title: 'Gerar contrato',
                                    message:
                                        "Você pode escolher entre um contrato com ou sem marca d'água. Fique à vontade para selecionar a opção que melhor atende às suas necessidades. Confira as opções abaixo.",
                                    children: (
                                        <GeneratePreviewCreditNote
                                            setPopupProps={setPopupProps}
                                        />
                                    ),
                                }),
                        }
                        : {
                            href: hasDraftDoc?.tempUrl,
                            target: '_blank',
                        },
                    submitApproval: handleSubmitApprovalCreditNoteAsync,
                    transferReceiptPdf: () => {
                        setModalProps({
                            title: 'Gerar comprovantes',
                            description: 'Escolha os comprovantes a serem gerados ',
                            children: (
                                <TransferReceiptPdfFormContainer
                                    setToast={setToast}
                                    onClose={handleOnClose}
                                    creditNoteRefetch={creditNoteRefetch}
                                />
                            ),
                        });
                    },
                    approveLiquidation: () => {
                        setModalProps({
                            title: 'Aprovar liquidação',
                            description: 'Conclua a etapa de Liquidação para encerrar a operação.',
                            children: (
                                <ApproveLiquidationContainer
                                    onClose={handleOnClose}
                                    handleApproveLiquidation={handleApproveLiquidation}
                                    creditData={creditData}
                                    formType="creditNote"
                                />
                            ),
                        });
                    },
                    generatePaymentLink: () => handleGeneratePaymentLink(),
                    rejectLiquidation: () => {
                        setPopupProps({
                            title: 'Rejeitar Liquidação',
                            message: 'Tem certeza que deseja rejeitar esta operação?',
                            children: (
                                <RejectCreditNote
                                    navigateWithFilters={navigateWithFilters}
                                    onErrorCatch={handleErrorResponse}
                                    setPopupProps={setPopupProps}
                                    statusCreditNote={watch('status')}
                                />
                            ),
                        });
                    },
                    approveSignature: () => {
                        setPopupProps({
                            title: 'Aprovar assinatura',
                            message: 'Tem certeza que deseja aprovar assinatura?',
                            children: (
                                <FormProvider
                                    validationSchema={validationSchemaRejectSignatureForm()}
                                    defaultValues={defaultValuesRejectSignatureForm}
                                    onSubmit={(values: FieldValues) =>
                                        handleSignature(false, 'approveSignature', values)
                                    }
                                >
                                    <ApproveSignature
                                        setModalProps={setModalProps}
                                        setPopupProps={setPopupProps}
                                    />
                                </FormProvider>
                            ),
                        });
                    },
                    handleSigature: () => {
                        setPopupProps({
                            title: 'Concluir assinatura',
                            message: 'Tem certeza que deseja concluir assinatura?',
                            children: (
                                <GenericChildrenAction
                                    onClick={() => handleSignature(false, 'signature')}
                                />
                            ),
                        });
                    },
                    rejectSignatures: () => {
                        setPopupProps({
                            title: 'Rejeitar Assinatura',
                            message: 'Tem certeza que deseja rejeitar esta operação?',
                            children: (
                                <RejectCreditNote
                                    navigateWithFilters={navigateWithFilters}
                                    onErrorCatch={handleErrorResponse}
                                    setPopupProps={setPopupProps}
                                    statusCreditNote={watch('status')}
                                />
                            ),
                        });
                    },
                    approvePaymentRevision: () => {
                        setDrawerProps({
                            title: 'Concluir Revisão',
                            description: 'Confira as informações antes de concluir revisão',
                            children: (
                                <PaymentRevisionFormContainer
                                    setToast={setToast}
                                    onClose={handleOnClose}
                                    personId={personId}
                                />
                            ),
                        });
                    },
                    sendSignatures: () => {
                        startRequest();
                        creditNoteAPI
                            .sendSignatoryCreditNoteById(id!, token!)
                            .then(() => {
                                queryClient.invalidateQueries({
                                    queryKey: ['credit-note-list'],
                                    refetchType: 'active',
                                });
                                navigateWithFilters('/ccb/operacoes');
                                endRequest(true);
                            })
                            .catch((response: ApiResponseError) => handleErrorResponse(response));
                    },
                    updateUploads: handleUpdateDocs,
                    simulate: handleSimulateCreditNoteAsync,
                    getSimulation: () => {
                        setPopupProps({
                            title: 'Recuperar Simulação',
                            message: 'Insira um ID para recuperar a simulação.',
                            children: (
                                <RecoverSimulationCreditNote
                                    setPopupProps={setPopupProps}
                                    setValue={setValue}
                                />
                            ),
                        });
                    },
                    checkCredPayOperation: () => {
                        creditNoteAPI
                            .checkCreditCardOperationsById(id!, token!)
                            .then(() => navigateWithFilters('/ccb/operacoes'))
                            .catch((response: ApiResponseError) => handleErrorResponse(response));
                    },
                    approveInstrument: () => {
                        setPopupProps({
                            title: 'Aprovar instrumento',
                            message: 'Tem certeza que deseja aprovar esta operação?',
                            children: (
                                <ApproveCreditNote
                                    navigateWithFilters={navigateWithFilters}
                                    onErrorCatch={handleErrorResponse}
                                    setPopupProps={setPopupProps}
                                />
                            ),
                        });
                    },
                    rejectInstrument: () => {
                        setPopupProps({
                            title: 'Rejeitar instrumento',
                            message: 'Tem certeza que deseja rejeitar esta operação?',
                            children: (
                                <RejectCreditNote
                                    navigateWithFilters={navigateWithFilters}
                                    onErrorCatch={handleErrorResponse}
                                    setPopupProps={setPopupProps}
                                    statusCreditNote={watch('status')}
                                />
                            ),
                        });
                    },
                    approveCredit: () => {
                        setPopupProps({
                            title: 'Aprovar operação',
                            message: 'Tem certeza que deseja aprovar esta operação?',
                            children: (
                                <ApproveCreditNote
                                    onErrorCatch={handleErrorResponse}
                                    setPopupProps={setPopupProps}
                                    navigateWithFilters={navigateWithFilters}
                                />
                            ),
                        });
                    },
                    rejectCredit: () => {
                        setPopupProps({
                            title: 'Rejeitar operação',
                            message: 'Tem certeza que deseja rejeitar a operação?',
                            children: (
                                <RejectCreditNote
                                    onErrorCatch={handleErrorResponse}
                                    setPopupProps={setPopupProps}
                                    statusCreditNote={watch('status')}
                                    navigateWithFilters={navigateWithFilters}
                                />
                            ),
                        });
                    },
                    approveCompliance: () => {
                        setPopupProps({
                            title: 'Aprovar compliance',
                            message: 'Tem certeza que deseja aprovar esta operação?',
                            children: (
                                <ApproveCreditNote
                                    onErrorCatch={handleErrorResponse}
                                    setPopupProps={setPopupProps}
                                    navigateWithFilters={navigateWithFilters}
                                />
                            ),
                        });
                    },
                    rejectCompliance: () => {
                        setPopupProps({
                            title: 'Rejeitar compliance',
                            message: 'Tem certeza que deseja rejeitar esta operação?',
                            children: (
                                <RejectCreditNote
                                    navigateWithFilters={navigateWithFilters}
                                    onErrorCatch={handleErrorResponse}
                                    setPopupProps={setPopupProps}
                                    statusCreditNote={creditData?.status}
                                />
                            ),
                        });
                    },
                    cancel: () => {
                        setPopupProps({
                            title: 'Cancelar operação',
                            message: 'Tem certeza que deseja cancelar a operação?',
                            children: (
                                <CancelFormContainer
                                    navigate={() => navigateWithFilters('/ccb/operacoes')}
                                    setPopupProps={setPopupProps}
                                    statusCreditNote={creditData?.status}
                                />
                            ),
                        });
                    },
                    deleteOp: () => {
                        setPopupProps({
                            title: 'Excluir operação',
                            message: 'Tem certeza que deseja excluir a operação?',
                            children: <GenericChildrenAction onClick={handleDelete} />,
                        });
                    },
                    manualUpdate: () => {
                        setModalProps({
                            title: 'Definir status',
                            description: `Tem certeza que deseja alterar o status da operação #${watch(
                                'creditNoteNo'
                            )}?`,
                            children: (
                                <ManualUpdate setToast={setToast} setModalProps={setModalProps} />
                            ),
                        });
                    },
                    sendDocuments: () => {
                        setPopupProps({
                            title: 'Destinatário',
                            message:
                                'Informe o e-mail do destinatário, para que seja enviado toda documentação da operação.',
                            children: (
                                <SendDocumentsCreditNote
                                    setToast={setToast}
                                    setPopupProps={setPopupProps}
                                />
                            ),
                        });
                    },
                    clone: () => {
                        creditNoteAPI
                            .getCreditNoteById(id!, token!)
                            .then((result) => {
                                return creditNoteAPI
                                    .duplicateCreditNote(result.data, token!, currentTenantId)
                                    .then(({ data }) =>
                                        navigateWithFilters(`/ccb/operacoes/${data}`)
                                    )
                                    .catch((response) => {
                                        const { errorMessage, fieldErrors } = mapErrorResponse(response);
                                        const getReasonMessage = () => {
                                            const hasErrorAprField = fieldErrors?.every(x => x.key === "amortization.apr");
                                            return (hasErrorAprField && fieldErrors?.length === 1) ? fieldErrors[0].helpMessage : "";
                                        }
                                        const reasonMessage = getReasonMessage();

                                        if (!isEmpty(reasonMessage)) {
                                            return showWarningToast(errorMessage, reasonMessage, setToast);
                                        };

                                        return handleErrorResponse(response)
                                    });
                            })
                            .catch((response) => handleErrorResponse(response));
                    },
                    unlockOperation: () => {
                        creditNoteAPI
                            .postRemoveBlock(id!, token!)
                            .then(() => {
                                const title = 'Operação desbloqueada com sucesso!';
                                const description = 'Ótimo! Agora você pode editar a operação.';
                                showSuccessToast(title, description, setToast);
                                navigateWithFilters(`/ccb/operacoes/${id}`);
                            })
                            .catch((response) => handleErrorResponse(response));
                    },
                }}
            >
                {children}
            </CreditNoteActionsContext.Provider>
        </>
    );
};

export function useCreditNoteActions(): CreditNoteActionsProps {
    const context = useContext(CreditNoteActionsContext);
    const {
        assign,
        isNewCreditNote,
        deleteCheckList,
        assignmentPreview,
        draftPreview,
        submitApproval,
        handleSigature,
        approveLiquidation,
        rejectLiquidation,
        approveSignature,
        rejectSignatures,
        approvePaymentRevision,
        sendSignatures,
        updateUploads,
        simulate,
        getSimulation,
        checkCredPayOperation,
        approveInstrument,
        rejectInstrument,
        approveCredit,
        rejectCredit,
        approveCompliance,
        rejectCompliance,
        cancel,
        deleteOp,
        manualUpdate,
        sendDocuments,
        clone,
        generatePaymentLink,
        status,
        uploads,
        unlockOperation,
        transferReceiptPdf,
        manualWarrantyMutate,
        approveMarginReserve,
        disapproveMarginReserve,
        reserveToken,
        showTokensRegistered,
        reprocessByStatus,
        fraudRecord,
        handleSiapeMarginQuery,
        installmentCreditNote, 
        checkAverbation
    } = context;

    return {
        assign,
        deleteCheckList,
        handleSigature,
        isNewCreditNote,
        assignmentPreview,
        draftPreview,
        submitApproval,
        approveLiquidation,
        rejectLiquidation,
        approveSignature,
        rejectSignatures,
        approvePaymentRevision,
        sendSignatures,
        updateUploads,
        simulate,
        getSimulation,
        checkCredPayOperation,
        approveInstrument,
        rejectInstrument,
        approveCredit,
        rejectCredit,
        approveCompliance,
        rejectCompliance,
        cancel,
        deleteOp,
        manualUpdate,
        sendDocuments,
        clone,
        generatePaymentLink,
        status,
        uploads,
        unlockOperation,
        transferReceiptPdf,
        manualWarrantyMutate,
        approveMarginReserve,
        disapproveMarginReserve,
        reserveToken,
        showTokensRegistered,
        reprocessByStatus,
        fraudRecord,
        handleSiapeMarginQuery,
        installmentCreditNote, 
        checkAverbation
    };
}
