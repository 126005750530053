import React, { Fragment } from 'react';
import { CheckboxWithTooltip } from '../HelpersTooltip/CheckboxWithTooltip';
import { ActionsPermissions } from 'components/ActionsPermissions/ActionsPermissions';

export const BankAccountActions = () => {
    return (
        <ActionsPermissions
            PermissionResource={<PermissionResourceBankAccount />}
            PermissionRootTenancy={<PermissionResourceBankAccountRootTenancy />}
        />
    );
};

const PermissionResourceBankAccount = () => {
    return (
        <Fragment>
            <CheckboxWithTooltip
                checkboxFormField={{
                    label: 'Aprovar beneficiário',
                    name: 'actions.AdminApproveBeneficiary',
                }}
                helpMessage="Permite aprovar um beneficiário de uma conta ativa."
            />
            <CheckboxWithTooltip
                checkboxFormField={{
                    label: 'Solicitar limites',
                    name: 'actions.RequestBankAccountLimit',
                }}
                helpMessage="Permite solicitar limites para Pix, Ted, Transferência Interna e Boleto."
            />
        </Fragment>
    )
};

const PermissionResourceBankAccountRootTenancy = () => {
    return (
        <Fragment>
            <CheckboxWithTooltip
                checkboxFormField={{
                    label: 'Adicionar lançamentos',
                    name: 'actions.AddAccountEntry',
                }}
                helpMessage="Permite o financeiro adicionar lançamentos de débito."
            />
            <CheckboxWithTooltip
                checkboxFormField={{
                    label: 'Aprovar limites',
                    name: 'actions.ApproveBankAccountLimit',
                }}
                helpMessage="Permissão exclusiva para colaboradores, aprova um limite no qual foi anteriormente solicitado."
            />
            <CheckboxWithTooltip
                checkboxFormField={{
                    label: 'Aprovar operador (admin)',
                    name: 'actions.AdminApproveOperator',
                }}
                helpMessage="Permissão exclusiva para admin, uma vez concedida, permite aprovar um operador."
            />

            <CheckboxWithTooltip
                checkboxFormField={{
                    label: 'Encerrar conta',
                    name: 'actions.TerminateAccount',
                }}
                helpMessage="Permissão exclusiva para admin, uma vez concedida, permite encerrar uma conta."
            />
            <CheckboxWithTooltip
                checkboxFormField={{
                    label: 'Visualizar acesso concedidos à conta bancária',
                    name: 'actions.ViewOperators',
                }}
                helpMessage="Permitir visualizar acessos concedidos à conta bancária e liberar um novo menu para gerenciamento deles."
            />
            <CheckboxWithTooltip
                checkboxFormField={{
                    label: 'Vincular novo operador à conta',
                    name: 'actions.RequestNewOperator',
                }}
                helpMessage="Solicita permissão para adicionar um novo operador a uma conta digital."
            />
            <CheckboxWithTooltip
                checkboxFormField={{
                    label: 'Visualizar pendentes',
                    name: 'actions.ViewPendingOperators',
                }}
                helpMessage="Permitir visualizar todos os operadores pendentes nos quais foram solicitados."
            />
            <CheckboxWithTooltip
                checkboxFormField={{
                    label: 'Visualizar todas as contas bancárias',
                    name: 'actions.ViewAllBankAccounts',
                }}
                helpMessage="Permite visualizar todas as contas bancárias."
            />
        </Fragment>
    )
}