import { DefaultRecordDetails, UploadGet } from 'contexts/apiRequestContext';

export interface PaymentListFilters {
    page: number;
    size: number;
    f_paymentDate?: string;
    f_amount?: number;
    f_status?: string;
}

export type OperatorsPedingApprove = {
    action: 'Approve' | 'Reject';
    sessionId: string;
    code: string;
    operatorIds: string[];
};

export type UpdateLevelOperator = {
    bankAccountId: string;
    operatorId: string;
    payload: EditOperatorType;
    token: string;
};

export interface OperatorListFilters {
    page?: number;
    size?: number;
    f_userUserId?: string;
    f_level?: number;
    f_status?: string;
}
export interface OperatorsPedingFilters {
    page: number;
    size: number;
    accountNumber?: string;
    typeAccount?: string;
    tenant?: string;
    f_level?: string;
}

export type ParamsDelete = {
    path: string;
    bankAccountId: string;
    userId: string;
};

export type ParamsCreateOperator = {
    level: string;
    userId: string;
    tenantName: string;
};

export type ActionApproveOrRejectType = {
    payload: PayloadApproveOrRejectType;
    resource: 'Beneficiary' | 'Operator' | 'Pay' | 'Transfer';
};

export type PayloadApproveOrRejectType = {
    action: 'Approve' | 'Reject';
    sessionId: string;
    code: string;
    operatorIds?: string[];
    beneficiaryBankAccounts?: ApproveBeneficiaryType[];
    payBankAccounts?: ApprovePayType[];
    transferBankAccounts?: TransferBankAccountsType[];
};

export type ApproveBeneficiaryType = {
    BankAccountId: string;
    BeneficiaryId: string;
};

export type ApprovePayType = {
    BankAccountId: string;
    PaymentId: string;
};

export type TransferBankAccountsType = {
    BankAccountId: string;
    TransferId: string;
};

export type ActionApprovePayment = {
    action: 'Approve' | 'Reject';
    sessionId: string;
    code: string | undefined;
};

export type CancelPaymentType = {
    paymentId: string;
    sessionId: string;
    code: string | undefined;
};

export type ParamsCancel = {
    path: string;
    bankAccountId: string;
    beneficiaryId: string;
};

export type EditOperatorType = {
    level: string;
};

export type ParamsAndFiltersStatement = {
    bankAccountId: string | undefined;
    initialDate: string;
    finalDate: string;
    token: string | undefined;
};

export type postBankAccountRequestOperatorProps = {
    userId: string;
    level: string;
    tenant: string;
};

export interface BlockReason extends DefaultRecordDetails {
    code: string;
    description: string;
    index: number;
}

export type CancelTransferProps = {
    bankAccountId: string;
    beneficiaryId: string;
    sessionId: string;
    code: string;
    token: string;
};

export type ApproveOrRejectTransfer = {
    bankAccountId: string;
    beneficiaryId: string;
    sessionId: string;
    code: string;
    action: 'Approve' | 'Reject';
    token: string;
};
export type ConsumptionBillPaymentType = {
    barCodeOrDigitableLine: string;
    paymentValue: number;
    paymentDate: Date;
    sessionId: string;
    code: string;
};

type ValuesHoursBusiness = {
    valueInCentsOwnOwnership: number;
    valueInCentsNaturalPerson: number;
    valueInCentsLegalPerson: number;
};

export type HoursBusinessLimit = {
    businessHours: ValuesHoursBusiness;
    nonBusinessHours: ValuesHoursBusiness;
};

export type StepsPayment =
    | 'requestGareIpva'
    | 'requestGareIcms'
    | 'requestDarf'
    | 'showInfoOfPayment'
    | 'formDrawer'
    | 'confirmationPayment'
    | 'confirmationMfa'
    | undefined;

export interface BankAccounTransferType {
    amount: number;
    transferDate: string;
    paymentPurpose?: string;
    bankAccountBeneficiaryCreate?: BankAccountBeneficiaryCreateType;
    saveBeneficiary: boolean;
    sessionId: string;
    code: string;
    bankAccountBeneficiaryId?: string;
}

type BankAccountBeneficiaryCreateType = {
    name: string;
    registrationNumber: string;
    bank: number;
    agency: string;
    accountNumber: string;
    accountType: string;
    operationTypeValue: string;
};

export interface StatementQueryDataType {
    accountingEntryReversal: boolean;
    amount: number;
    description: string;
    id: string;
    type: string;
    information: string;
    isfare: boolean;
    issueDate: string;
    movement: string;
    movementDisplay: string;
    registrationNumber: string;
    reversedAccountingEntry: boolean;
}

export interface BalancesType {
    availableValue: number;
    issueDate: string;
    transactions: StatementQueryDataType[];
}

export type BankAccountKeyPixType = {
    createdAt: string;
    ownerAt: string;
    pixKey: string;
    type: string;
};

export type PostBankAccountKeyProps = {
    type: string;
    pixKey: string | null;
};

export type PostBankAccountClaimPixKeyProps = {
    type: string;
    pixKey: string;
    claimType: string;
};

export type ManagementPixKeyType = {
    typeDisplay: string;
} & PostBankAccountClaimPixKeyProps & BankAccountKeyPixType;

export type PutBankAccountClaimPixKeyType = {
    claimId: string;
    queueClaimStatus: 'Confirm' | 'Cancel' | 'Finish';
};

export type DeleteOperatorByOperatorIdType = {
    operator: string
    accountNumber: string;
    operatorId: string;
    sessionId: string;
    code: string;
};

const commonPixKeys = [
    { label: 'Telefone', value: 'Phone' },
    { label: 'Email', value: 'Email' },
    { label: 'Aleatória', value: 'Automatic' },
    { label: 'Agência e Conta', value: 'AgencyAndAccount' },
];

export const pixKeyTypeOpt = (isLegalPerson: boolean) => [
    isLegalPerson
        ? { label: 'CNPJ', value: 'LegalRegistrationNumber' }
        : { label: 'CPF', value: 'NaturalRegistrationNumber' },
    ...commonPixKeys,
];

export const pixKeyTypeValueOpt = [
    { label: 'CPF', value: 'NaturalRegistrationNumber' },
    { label: 'CNPJ', value: 'LegalRegistrationNumber' },
    ...commonPixKeys,
];


type RecordTypeOption = {
    [key: number]: string;
};

export const recordTypeOptions: RecordTypeOption = {
    0: "valueInCentsOwnOwnership",
    1: "valueInCentsNaturalPerson",
    2: "valueInCentsLegalPerson",
};

export interface IBankAccountPixQRcode<T> {
    bankAccountId: string;
    payload: T;
    token: string;
}

export type PixQRcodeStatic = {
    description: string;
} & PixQRcodeType;

export type PixQRcodeImmediateDynamic = {
    payerRegistrationNumber: string;
    payerName: string;
} & PixQRcodeType;

export type PixQRcodeType = {
    pixKey: string;
    type: string;
    valueInCents: number;
};
export interface GetPixQRcodeType {
    pixKey: string;
    pixKeyType: string;
    pixKeyTypeDisplay: string;
    endToEndId: string;
    beneficiary: Beneficiary;
    payer: Payer;
    valueInCents: number;
    originalValueInCents: number;
    interestValueInCents: number;
    assessmentValueInCents: number;
    discountValueInCents: number;
    rebateValueInCents: number;
    dueDate: string;
    validityAfterExpiration: number;
    txId: string;
    qrCodeType: string;
    pixType: string;
}

export interface Beneficiary {
    agency: string;
    accountNumber: string;
    accountType: string;
    accountTypeDisplay: string;
    ispb: string;
    personType: string;
    personTypeDisplay: string;
    registrationNumber: string;
    name: string;
    bankName: string;
}

export interface Payer {
    personType: string;
    personTypeDisplay: string;
    registrationNumber: string;
    name: string;
}

export interface LimitsReadModel extends DefaultRecordDetails {
    status: number
    statusDisplay: string
    uploads: UploadGet[]
    pixLimit: BaseLimit
    bankSlipLimit: BaseLimit
    transferLimit: BaseLimit
    tedLimit: BaseLimit
    updateStatusUser: any
    bankAccount: any
    bankAccountId: string
    existPendingLimitsApproval: boolean
}

export interface BaseLimit {
    businessHours: BaseBusinessHours
    nonBusinessHours: BaseBusinessHours
}

export interface BaseBusinessHours {
    valueInCentsOwnOwnership: number
    valueInCentsNaturalPerson: number
    valueInCentsLegalPerson: number
}
