import { useState } from 'react';
import { useParams } from 'react-router';
import { Error } from 'components/Errors/Error';
import { AxiosResponse } from 'axios';
import { FavoredTab } from './FavoredTab';
import { Button, CloseIcon, DeleteIcon, Drawer, Popup, SaveIcon } from '@uy3/web-components';
import {
    ApiResponseError,
    ToastType,
    showErrorToast,
    showSuccessToast,
    toastState,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { RefreshProgress } from 'components/RefreshProgress';
import { CreateBeneficiaryType, IMFAState } from 'contexts/bankAccount/bankAccountType';
import { useBankAccountBeneficiariesList } from 'contexts/bankAccount/bankAccountContext';
import { useGenerateSessionIdMutation, useIdentity } from 'contexts/identityContext';
import { BankAccountItemDetails } from 'pages/BankAccount/BankAccountActive/BankAccountDrawerItemsDetails/BankAccountItemDetails';
import {
    approveOrRejectBankAccountByResource,
    beneficiaryBatchApproval,
    cancelOperatorAndFavored,
    createBeneficiary,
    deleteByBankAccountId,
} from 'services/accounts/bankAccount/bankAccount';
import { FilterProvider } from 'contexts/filterContext';
import { statusBankAccountOptions } from 'components/DataTableFilters/BankAccountFilters/BankAccountFilters';
import {
    ActionApproveOrRejectType,
    ApproveBeneficiaryType,
    ParamsCancel,
    ParamsDelete,
} from 'services/accounts/bankAccount/bankAccount.types';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';
import FavoredHeader from './FavoredHeader';
import { FieldValues } from 'react-hook-form';
import DrawerMFA from '../DrawerMFA/DrawerMFA';
import { Stack } from '@mui/material';
import { activeTheme } from 'services/theme';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { handleSubmitQueryData } from 'helpers/methods/queryBasicDataPerson';
import { IQueryBasicData } from 'contexts/personContext';
import { BankAccountFavoredFormContainer } from './BankAccountFavoredForm/BankAccountFavoredFormContainer';
import { GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { everyItemsIsPendingApproval } from 'helpers/methods/GenericMethods';

type MessageSuccessType = 'cancel' | 'delete' | 'create' | 'approve' | 'reject';

const theme = activeTheme();
export const FavoredTabContainer = () => {
    const { bankAccountId } = useParams();
    const { token } = useIdentity();
    const [userId, setUserId] = useState();
    const [toast, setToast] = useState<ToastType>(toastState);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([]);
    const { setSubmitError, startRequest, endRequest, submitting } = useApiRequest();
    const [stepFavored, setStepFavored] = useState<string | undefined>(undefined);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [favoredInfo, setFavoredInfo] = useState<any>(undefined);

    const {
        beneficiarieData,
        beneficiarieStatus,
        beneficiarieError,
        refetch: beneficiarieRefetch,
        isFetching,
        isLoading
    } = useBankAccountBeneficiariesList(bankAccountId!, {
        page,
        size: rowsPerPage,
    });


    const handleSelectionModelChange = (selectionModel: GridSelectionModel) => setRowsSelected(selectionModel);

    const beneficiariesFilteredBatchList = beneficiarieData?.data?.filter((x: any) => {
        return rowsSelected?.includes(x?.id);
    });

    const handleResponseError = (error: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(error);
        endRequest(false);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const handleResponseSuccess = (type: MessageSuccessType) => {
        beneficiarieRefetch();
        endRequest(true);
        setStepFavored(undefined);
        const descriptionOptions: { [key: string]: string } = {
            cancel: `Favorecido cancelado com sucesso`,
            create: `Favorecido criado com sucesso`,
            delete: `Favorecido excluído com sucesso`,
            approve: `Favorecido aprovado com sucesso`,
            reject: `Favorecido rejeitado com sucesso`,
        };
        const title = 'Sucesso!';
        const description = descriptionOptions[type];
        showSuccessToast(title, description, setToast);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const onChangePage = (page: number) => setPage(page);

    const { mutateGenerateSessionId } = useGenerateSessionIdMutation();
    const generateSessionIdAsync = async (
        values: IMFAState,
        method: (response: AxiosResponse<string, any>) => void
    ) => {
        startRequest();
        mutateGenerateSessionId({
            userPassword: values.password,
            then: (response: AxiosResponse<string, any>) => method(response),
        });
    };

    const createFavoredMethod = async (formValues: FieldValues) => {
        startRequest();
        let fieldValues = {
            name: formValues.name,
            registrationNumber: formValues.registrationNumber,
            bank: formValues.bank,
            agency: formValues.agency,
            accountNumber: formValues.accountNumber,
            accountType: formValues.accountType,
            keyPix: formValues.keyPix,
            operationTypeValue: formValues.operationTypeValue,
            pixKeyTypeValue: formValues.pixKeyTypeValue,
        } as CreateBeneficiaryType;

        createBeneficiary(bankAccountId!, fieldValues, token!)
            .then(() => handleResponseSuccess('create'))
            .catch((response: ApiResponseError) => handleResponseError(response));
    };

    const onDelete = async () => {
        startRequest();
        const data = {
            bankAccountId,
            userId: userId!,
            path: 'Beneficiary',
        } as ParamsDelete;
        deleteByBankAccountId(data, token!)
            .then(() => handleResponseSuccess('delete'))
            .catch((response: ApiResponseError) => handleResponseError(response));
    };

    const handleApproveOrReject = async (values: IMFAState) => {
        startRequest();
        generateSessionIdAsync(values, (response) => {
            const transferBankAccountsTypeBatch = beneficiariesFilteredBatchList?.map((x: any) => {
                return {
                    BankAccountId: bankAccountId!,
                    BeneficiaryId: x?.id
                } as ApproveBeneficiaryType
            })
            const data = {
                payload: {
                    action: stepFavored === 'confirmMfaApprove' ? 'Approve' : 'Reject',
                    code: values.code,
                    sessionId: response?.data,
                    beneficiaryBankAccounts: rowsSelected?.length > 0 ? transferBankAccountsTypeBatch : [{ BankAccountId: bankAccountId, BeneficiaryId: userId! as string }]
                },
                resource: 'Beneficiary'
            } as ActionApproveOrRejectType;

            if (rowsSelected?.length > 0) {
                return beneficiaryBatchApproval(data?.payload, token!)
                    .then(() => handleResponseSuccess(stepFavored === 'confirmMfaApprove' ? 'approve' : 'reject'))
                    .catch((response: ApiResponseError) => handleResponseError(response));
            }
            approveOrRejectBankAccountByResource(bankAccountId!, userId!, data, token!)
                .then(() => handleResponseSuccess('reject'))
                .catch((response: ApiResponseError) => handleResponseError(response));
        });
    };

    const onCancel = async () => {
        const data = {
            bankAccountId,
            beneficiaryId: userId!,
            path: 'Beneficiary',
        } as ParamsCancel;
        cancelOperatorAndFavored(data, token!)
            .then(() => handleResponseSuccess('cancel'))
            .catch((response: ApiResponseError) => handleResponseError(response));
    };

    const onClose = () => {
        setStepFavored(undefined);
        setSubmitError(undefined);
    };

    const getQueryBasicDataPerson = async (registrationNumber: string, onSuccess: (values: IQueryBasicData) => void) => {
        await handleSubmitQueryData(
            registrationNumber,
            token!,
            onSuccess,
            (response: ApiResponseError) => handleResponseError(response)
        );
    };

    if (beneficiarieStatus === 'error') <Error error={beneficiarieError} />;

    const drawerForm: { [key: string]: JSX.Element } = {
        bankAccountfavoredForm: (
            <BankAccountFavoredFormContainer
                onClose={onClose}
                getQueryBasicDataPerson={getQueryBasicDataPerson}
                createFavoredMethod={createFavoredMethod}
            />
        ),
        confirmMfaApprove: <DrawerMFA onSubmit={handleApproveOrReject} onClose={onClose} />,
        confirmMfaReject: <DrawerMFA onSubmit={handleApproveOrReject} onClose={onClose} />,
    };
    const form = drawerForm[stepFavored!];

    const titleDrawer: { [key: string]: string } = {
        bankAccountfavoredForm: 'Criar favorecido',
        confirmMfaApprove: 'Aprovar favorecido',
        confirmMfaReject: 'Reprovar favorecido',
    };
    const title = titleDrawer[stepFavored!];

    const openDrawer: { [key: string]: boolean } = {
        bankAccountfavoredForm: true,
        confirmMfaApprove: true,
        confirmMfaReject: true,
    };

    const open = openDrawer[stepFavored!];

    return (
        <ErrorBoundary fallback='favorecidos'>
            <Toast toast={toast} setToast={setToast} />
            {!isLoading &&
                <RefreshProgress refreshing={isFetching || beneficiarieStatus === 'loading'} />}
            <FavoredHeader
                isFetching={isFetching || submitting}
                beneficiarieRefetch={beneficiarieRefetch}
                setStepFavored={setStepFavored}
                enableBatchActionApprove={everyItemsIsPendingApproval(beneficiariesFilteredBatchList ?? [])}
            />
            <Drawer
                anchor="right"
                title="Detalhes da aprovação"
                open={favoredInfo !== undefined}
                onClose={() => setFavoredInfo(undefined)}
            >
                <BankAccountItemDetails
                    informationData={favoredInfo}
                    setOpenDrawer={() => setFavoredInfo(undefined)}
                    title="Informações do favorecido"
                />
            </Drawer>

            <FilterProvider
                availableFilters={{
                    searchString: { label: 'Todos', type: 'text' },
                    f_name: { label: 'Nome', type: 'text' },
                    f_registrationNumber: { label: 'CPF/CNPJ', type: 'text' },
                    f_status: {
                        label: 'Status',
                        type: 'select',
                        multiple: false,
                        options: statusBankAccountOptions,
                    },
                }}
            >
                <ListSkeleton isLoading={isLoading}>
                    <FavoredTab
                        setUserId={setUserId}
                        setStepFavored={setStepFavored}
                        setFavoredInfo={setFavoredInfo}
                        queryData={beneficiarieData}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={onChangeRowsPerPage}
                        setPage={onChangePage}
                        page={page}
                        handleSelectionModelChange={handleSelectionModelChange}
                        rowsSelected={rowsSelected}
                    />
                </ListSkeleton>
            </FilterProvider>

            <Popup
                title="Remover favorecido"
                open={stepFavored === 'confirmMfaDelete'}
                onClose={onClose}
                text="Tem certeza que deseja excluir o regitro?"
            >
                <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3}>
                    <Button
                        startIcon={
                            <CloseIcon
                                htmlColor={theme.palette.primary.main}
                                sx={{ height: 19, width: 20 }}
                            />
                        }
                        name="btn-close-popup"
                        variant="outlined"
                        size="medium"
                        onClick={onClose}
                    >
                        Fechar
                    </Button>
                    <Button
                        name="btn-delete-popup"
                        onClick={onDelete}
                        variant="contained"
                        disabled={submitting}
                        startIcon={
                            <DeleteIcon
                                htmlColor={theme.palette.common.white}
                                sx={{ height: 19, width: 20 }}
                            />
                        }
                        sx={{ justifyContent: 'center' }}
                    >
                        Confirmar
                    </Button>
                </Stack>
            </Popup>
            <Popup
                title="Cancelar favorecido"
                open={stepFavored === 'confirmMfaCancel'}
                onClose={onClose}
                text="Tem certeza que deseja cancelar esse favorecido?"
            >
                <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3}>
                    <Button
                        startIcon={
                            <CloseIcon
                                htmlColor={theme.palette.primary.main}
                                sx={{ height: 19, width: 20 }}
                            />
                        }
                        name="btn-close-popup"
                        variant="outlined"
                        size="medium"
                        onClick={onClose}
                    >
                        Fechar
                    </Button>
                    <Button
                        name="btn-delete-popup"
                        onClick={onCancel}
                        variant="contained"
                        startIcon={
                            <SaveIcon
                                htmlColor={theme.palette.common.white}
                                sx={{ height: 19, width: 20 }}
                            />
                        }
                        sx={{ justifyContent: 'center' }}
                    >
                        Confirmar
                    </Button>
                </Stack>
            </Popup>

            <Drawer anchor="right" title={title} open={open} onClose={onClose}>
                {form}
            </Drawer>
        </ErrorBoundary>
    );
};
