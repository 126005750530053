import { string, object } from 'yup';

export function validationPermissionFormSchema() {
    return object().shape({
        resource: string().typeError("Recurso precisa ser preencido.").required("Recurso: precisa ser preencido."),
        readPermission: string().nullable(),
        createPermission: string().nullable(),
        updatePermission: string().nullable(),
        deletePermission: string().nullable(),
        actions: object(),
    });
};

export const defaultValuesPermissionForm = {
    resource: "",
    readPermission: "",
    createPermission: "",
    updatePermission: "",
    deletePermission: "",
    actions: {
        SendApproval: false,
        AssignRecord: false,
        Cancel: false,
        Clone: false,
        ComplianceApproval: false,
        CreditApproval: false,
        DraftPreview: false,
        InstrumentApproval: false,
        LiquidationApproval: false,
        LiquidationPaymentRevision: false,
        ManualUpdate: false,
        ReadDataSet: false,
        RegisterFgtsSaqueAniversario: false,
        RemoveBlock: false,
        RequestDataSet: false,
        RestoreRecord: false,
        SendAssigmentEmail: false,
        FinishSignature: false,
        SendNotification: false,
        SendToWedCred: false,
        SetFund: false,
        SignatureValidation: false,
        SubmitApproval: false,
        UploadDocs: false,
        ViewOperators: false,
        RequestNewOperator: false,
        ViewPendingOperators: false,
        ViewAllBankAccounts: false,
        AdminApproveOperator: false,
        OpenBankAccount: false,
        AdminCancel: false,
        ApproveBankAccountLimit: false,
        RequestBankAccountLimit: false,
        AddAccountEntry: false,
        AdminApproveBeneficiary: false,
        TerminateAccount: false,
        EnableUser: false,
        DisableUser: false, 
        StartSignatureWorkFlow: false, 
        AllowUpdatePermissionsSameTenant: false, 
        ResendNotificationSignatureWorkFlow: false, 
        CancelSignatureWorkFlow: false,
        ApproveWarranty: false,
        ApproveMarginReserve: false,
        RestartWorkflow: false, 
        IncludeBeneficiaryProduct: false
    }
};