/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { ConciliacaoLiquidacaoForm } from './ConciliacaoLiquidacaoForm';
import { Button, CardData, CompleteIcon, Typography } from '@uy3/web-components';
import { activeTheme } from 'services/theme';
import { iconSx } from 'contexts/apiRequestContext';
import { IAdicional, IFaltante, IResponseQueryConcilicao } from 'services/remessasVortx/remessasVortx.type';
import { useFormContext } from 'contexts/formContext';
import { AdditionalsList } from '../AdditionalsList/AdditionalsList';
import { MissingList } from '../MissingList/MissingList';
import { useWatch } from 'react-hook-form';
import { formatCurrencyInCents } from 'helpers/formats/Currency';

const theme = activeTheme();

type ConciliacaoLiquidacaoCaixaProps = {
    isLoading: boolean
    queryResponse: IResponseQueryConcilicao | null
}

export const ConciliacaoLiquidacaoFormContainer: React.FC<ConciliacaoLiquidacaoCaixaProps> = ({ isLoading, queryResponse }) => {
    const { setValue, watch, control } = useFormContext();

    const currentValuesForm: IResponseQueryConcilicao = useWatch({ control, name: 'query_response' });

    useEffect(() => {
        if (queryResponse !== null) {
            setValue('query_response', queryResponse as IResponseQueryConcilicao);
        }
    }, [queryResponse])


    const aditionalsList = watch('query_response.adicionais') as IAdicional[];
    const missingList = watch('query_response.faltantes') as IFaltante[];

    return (
        <Stack>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <Typography
                    variant="h4"
                    color="neutral.dark"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="32px"
                    lineHeight="38.4px"
                >
                    Conciliação Caixa
                </Typography>
                <Button
                    variant='text'
                    size='large'
                    type='submit'
                    disabled={isLoading}
                    sx={{ color: theme.palette.common.black }}
                    startIcon={<CompleteIcon sx={iconSx} htmlColor={isLoading ? 'grey' : theme.palette.common.black} />}
                >
                    Executar
                </Button>
            </Stack>

            <ConciliacaoLiquidacaoForm />

            {queryResponse !== null && (
                <Stack spacing={2} mt={2} mr={3}>
                    <Typography
                        variant="h6"
                        color="neutral.dark"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="23px"
                        lineHeight="38.4px"
                    >
                        Valores
                    </Typography>

                    <CardData
                        listItem={[
                            { id: '1', title: 'Valor recebido ', value: formatCurrencyInCents(currentValuesForm?.valorRecebido) },
                            { id: '2', title: 'Valor esperado ', value: formatCurrencyInCents(currentValuesForm?.valorEsperado) }
                        ]}
                    />
                </Stack>
            )}

            {!!aditionalsList && aditionalsList?.length > 0 &&
                <Stack margin='30px 0'>
                    <AdditionalsList queryData={aditionalsList} />
                </Stack>
            }

            {!!missingList && missingList?.length > 0 &&
                <Stack margin='10px 0'>
                    <MissingList queryData={missingList} />
                </Stack>
            }
        </Stack>
    );
}

