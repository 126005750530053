import { creditAxiosApi } from "services/axiosApi/axiosApi";
import { postBankAccountRequestOperatorProps } from "../bankAccount/bankAccount.types";
import { BankAccountAssign, BankAccountRequestProps, DisapprovedBankAccountProps, IDeleteParamsBankAccountRequestParams, IDraftpreviewBankAccountRequest, UpdateDocumentsProps, approveInstrumentByIdProps } from "./bankAccountRequest.types";

var url = "/BankAccountRequest";

export async function getBankAccountRequestList(filters: BankAccountRequestProps, token: string) {

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters
    };

    return await creditAxiosApi.get(url, config);
};

export async function getBankAccountRequestById(bankAccountId: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.get(url + `/${bankAccountId}`, config);
}

export async function editOrCreateBankAccRequest(data: any, bankAccountId?: string, token = "", tenant?: string) {

    const type = bankAccountId === 'nova' ? 'create' : 'edit';

    var url = type === 'edit' ? `/BankAccountRequest/${bankAccountId}` : `/BankAccountRequest`;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant }
    };

    const request = {
        create: {
            function: () => creditAxiosApi.post(url, data, config),
            successMessage: 'Conta bancaria cadastrada com sucesso',
            errorMessage: 'Ocorreu um erro ao cadastrar a solicitação de conta digital'
        },
        edit: {
            function: () => creditAxiosApi.put(url, data, config),
            successMessage: 'Conta bancaria atualizada com sucesso',
            errorMessage: 'Ocorreu um erro ao atualizar a solicitação de conta digital'
        }
    };

    return await request[type].function();
};

export async function approveBankAccountById(id: string, message: string, token: string) {
    var url = `/BankAccountRequest/${id}/approve`;
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await creditAxiosApi.post(url, { message }, config);
}


export async function disapprovedBankAccountById(id: string, payload: DisapprovedBankAccountProps, token: string) {
    const url = `/BankAccountRequest/${id}/disapprove`;

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.post(url, payload, config);
}

export async function postBankAccountRequestSubmitApproval(id: string, token: string) {
    const url = `/BankAccountRequest/${id}/submitApproval`;

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.post(url, {}, config);
}

export async function deleteBankAccountRequestById(id: string, token: string) {
    const url = `/BankAccountRequest/${id}`;

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.delete(url, config);
}

export async function updateBankAccountRequestById(id: string, token: string) {
    const url = `/BankAccountRequest/${id}`;

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.put(url, {}, config);
}

export async function manualUpdateBankAccountRequest(
    id: string,
    data: any,
    token: string,
    tenant: string | undefined
) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant },
    };

    return await creditAxiosApi.post(url + `/${id}/manualUpdate`, data, config);
}

export async function approvalRevisionById(id: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.post(url + `/${id}/approvalRevision`, {}, config);
}

export async function postOpenBankAccountRequest(bankAccountId: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.post(url + `/${bankAccountId}/openBankAccount`, {}, config);
}

export async function postAssignBankAccountRequest(bankAccountId: string, payload: BankAccountAssign, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.post(url + `/${bankAccountId}/assign`, payload, config);
}

export async function postBankAccountRequestOperator(bankAccountId: string, payload: postBankAccountRequestOperatorProps, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.post(url + `/${bankAccountId}/BankAccountRequestOperator`, payload, config);
}

export async function updateDocumentsBankAccountRequest(bankAccountId: string, payload: UpdateDocumentsProps, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.put(`/BatchAssignment/${bankAccountId}/upload`, payload, config);
}

export async function approveInstrumentById(id: string, payload: approveInstrumentByIdProps, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.post(url + `/${id}/approveInstrument`, payload, config);
}

export async function disapproveInstrumentById(id: string, payload: any, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.post(url + `/${id}/disapproveInstrument`, payload, config);
}

export async function approveOrRejectSignatureById(bankAccountRequestId: string, body: object, isRepprove: boolean, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
            reprove: isRepprove
        }
    };
    return await creditAxiosApi.post(url + `/${bankAccountRequestId}/approveSignature`, body, config);
}

export async function draftpreviewBankAccountRequest(
    bankAccountRequestId: string, 
    params: IDraftpreviewBankAccountRequest, 
    token: string
) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params
    };

    return await creditAxiosApi.post(url + `/${bankAccountRequestId}/draftpreview`, {}, config);
}

export async function deleteBankAccountRequestOperator(
    operatorId: string, 
    params: IDeleteParamsBankAccountRequestParams, 
    token: string
) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }, 
        params
    };

    return await creditAxiosApi.delete(url + `/BankAccountRequestOperator/${operatorId}`,  config);
}