import { Grid } from '@mui/material'
import { optionsTypeAccountComplete } from 'components/DataTableFilters/BankAccountFilters/BankAccountFilters'
import { AutocompleteOptionList } from 'components/Forms/Autocomplete'
import { SelectFormField, TextFormField } from 'components/Forms/FormFields'
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField'
import React, { Fragment } from 'react'

type BankTransferFieldsFormProps = {
    bankListAutocomplete: AutocompleteOptionList
}

export const BankTransferFieldsForm: React.FC<BankTransferFieldsFormProps> = ({bankListAutocomplete}) => {

    return (
        <Fragment>
            <Grid item xs={6}>
                <AutocompleteField
                    name="bank"
                    label="Selecione um banco"
                    displayName="bankName"
                    {...bankListAutocomplete}
                />
            </Grid>
            <Grid item xs={6}>
                <TextFormField
                    variant="outlined"
                    label="Agência (sem o digito)"
                    name="agency"
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <TextFormField
                    variant="outlined"
                    label="Conta (com o digito)"
                    name="accountNumber"
                    fullWidth
                />
            </Grid>
            <Grid item xs={6}>
                <SelectFormField
                    name="accountType"
                    label="Tipo de conta"
                    defaultValue={false}
                    options={optionsTypeAccountComplete}
                />
            </Grid>
        </Fragment>
    )
}
