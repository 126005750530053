import { lazy, Suspense, useState } from 'react';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import { useIdentity } from 'contexts/identityContext';
import { useUserData } from 'contexts/userContext';
import { GenericFormSkeletonSuspense } from 'components/Skeleton/GenericFormSkeleton';
import { ApiResponseError, toastState, ToastType } from 'contexts/apiRequestContext';
import { ErrorBox } from 'components/Errors/ErrorBox/ErrorBox';
import { LogoutAction } from './LogoutAction/LogoutAction';
import Toast from 'components/Toast/Toast';
import { MyProfileStackContainer } from './MyProfile.style';

const MyProfileHeader = lazy(() => import('./MyProfileHeader'));
const AreaUser = lazy(() => import('./UserArea/UserArea'));
const SecurityPrivacyOptions = lazy(
  () => import('./SecurityPrivacyOptions/SecurityPrivacyOptions')
);

export const MyProfileContainer = () => {
  const [toast, setToast] = useState<ToastType>(toastState);
  const { user } = useIdentity();
  const { data: userData, error, isLoading, status } = useUserData(user?.id!);

  return (
    <ErrorBoundary fallback="Meu perfil">
      <ErrorBox error={error as ApiResponseError} resource="Meu perfil" status={status}>
        <Suspense fallback={<GenericFormSkeletonSuspense />}>
          <Toast setToast={setToast} toast={toast} />
          <MyProfileStackContainer spacing={4}>
            <MyProfileHeader
              pb={-2}
              isLoading={isLoading}
            />
            <AreaUser
              userData={userData!}
              isLoading={isLoading}
              />
            <SecurityPrivacyOptions
              userData={userData!}
              isLoading={isLoading}
              setToast={setToast}
            />
            {!isLoading && <LogoutAction />}
          </MyProfileStackContainer>
        </Suspense>
      </ErrorBox>
    </ErrorBoundary>
  );
};
