import { AxiosResponse } from 'axios';
import {
    ApiResponseError,
    DefaultRecordDetails,
    PutRecordApiResponse,
} from 'contexts/apiRequestContext';
import { ReactNode } from 'react';
import { PrimarySpan } from 'components/PrimarySpan/PrimarySpan';
import { Typography } from '@uy3/web-components';
import { creditAxiosApi } from "services/axiosApi/axiosApi";
export interface PrincipalPermissionProps {
    principalId: string | undefined;
    principalType: 'User' | 'Group' | 'tenant' | 'application';
}

export interface GetPermissionProps extends PrincipalPermissionProps { }

export interface CreatePermissionProps extends PrincipalPermissionProps {
    permissions?: any;
    tenant?: string | undefined;
}

export const permissionLevel = ['None', 'OnlyMine', 'MyGroups', 'All'];

type PermissionTypesArray = {
    [index: number]: string;
};

const PermissionTypes: PermissionTypesArray = {
    0: 'Read',
    1: 'Create',
    2: 'Update',
    3: 'Delete',
    4: 'SubmitApproval',
    5: 'ComplianceApproval',
    6: 'CreditApproval',
    7: 'InstrumentApproval',
    8: 'LiquidationApproval',
    9: 'LiquidationPaymentRevision',
    10: 'Clone',
    11: 'UploadDocs',
    12: 'DraftPreview',
    13: 'AssigmentPreview',
    14: 'SendAssigmentEmail',
    15: 'ManualUpdate',
    16: 'RemoveBlock',
    17: 'SignatureValidation',
    18: 'RegisterFgtsSaqueAniversario',
    19: 'Cancel',
    20: 'AssignRecord',
    21: 'SendNotification',
    22: 'RestoreRecord',
    23: 'ReadDataSet',
    24: 'RequestDataSet',
    25: 'SetFund',
    26: 'SendToWedCred',
    27: 'FinishSignature',
    28: 'RequestNewOperator',
    29: 'ViewPendingOperators',
    30: 'AdminApproveOperator',
    31: 'AdminCancel',
    32: 'ViewOperators',
    33: 'OpenBankAccount',
    34: 'ApproveBankAccountLimit',
    35: 'RequestBankAccountLimit',
    36: 'AdminApproveBeneficiary',
    37: 'ViewPendingApprovals',
    38: 'SimulateAmortization',
    39: 'ViewAllBankAccounts',
    40: 'AdminApproveTransfer', // not implemented by interface
    41: 'AddAccountEntry',
    42: 'StartSignatureWorkFlow',
    43: 'CancelSignatureWorkFlow',
    44: 'ResendNotificationSignatureWorkFlow',
    45: 'EnableUser',
    46: 'DisableUser',
    47: 'AllowUpdatePermissionsSameTenant',
    48: 'ApproveWarranty',
    49: 'ApproveMarginReserve',
    50: 'RestartWorkflow',
    51: 'TerminateAccount',
    52: 'IncludeBeneficiaryProduct',
};

export type BasicPermissionType =
    | 'Read'
    | 'Create'
    | 'Update'
    | 'Delete'
    | 'ViewPendingOperators'
    | 'ComplianceApproval';

export interface ActionsButton {
    SubmitApproval: boolean;
    Clone: boolean;
    AssigmentPreview: boolean;
    AssignRecord: boolean;
    Cancel: boolean;
    ComplianceApproval: boolean;
    CreditApproval: boolean;
    DraftPreview: boolean;
    InstrumentApproval: boolean;
    LiquidationApproval: boolean;
    LiquidationPaymentRevision: boolean;
    ManualUpdate: boolean;
    ReadDataSet: boolean;
    RegisterFgtsSaqueAniversario: boolean;
    RemoveBlock: boolean;
    RequestDataSet: boolean;
    RestoreRecord: boolean;
    SendAssigmentEmail: boolean;
    FinishSignature: boolean;
    SendNotification: boolean;
    SendToWedCred: boolean;
    SetFund: boolean;
    SignatureValidation: boolean;
    UploadDocs: boolean;
    AdminCancel: boolean;
    AdminApproveBeneficiary: boolean;
    ApproveWarranty: boolean;
    StartSignatureWorkFlow: boolean;
    ResendNotificationSignatureWorkFlow: boolean;
    CancelSignatureWorkFlow: boolean;
    ApproveMarginReserve: boolean;
    RestartWorkflow: Boolean
}

export interface ActionsDetails {
    ViewOperators: boolean;
    RequestNewOperator: boolean;
    ViewPendingOperators: boolean;
    AdminApproveOperator: boolean;
    ViewAllBankAccounts: boolean;
    TerminateAccount: boolean;
    RequestBankAccountLimit: boolean;
    AddAccountEntry: boolean;
    ApproveBankAccountLimit: boolean;
    // AdminApproveTransfer: boolean; not implemented by interface
    // AdminApprovePayment: boolean; not implemented by interface
    EnableUser: boolean;
    DisableUser: boolean;
    AllowUpdatePermissionsSameTenant: boolean
}
export interface ActionsBankAccountRequest {
    OpenBankAccount: boolean;
}

export type PermissionModel = {
    resource: string;
    resourceDisplay: string;
    createPermission?: string;
    updatePermission?: string;
    readPermission: string;
    deletePermission?: string;
    actions?: any | ActionsButton | ActionsDetails | ActionsBankAccountRequest;
};

export type PermissionLevel = 'None' | 'OnlyMine' | 'MyGroups' | 'All';

export interface Permission {
    level: PermissionLevel;
    resource: PermissionResource;
}

export interface PermissionFilter {
    type: BasicPermissionType;
    resource: PermissionResource | PermissionResource[];
    hidden?: boolean
}


export type PermissionResource =
    | 'CreditNote'
    | 0
    | 'User'
    | 100
    | 'UserGroup'
    | 101
    | 'BankAccount'
    | 200
    | 'BankAccountRequest'
    | 201
    | 'LegalPerson'
    | 300
    | 'NaturalPerson'
    | 310
    | 'Person'
    | 320
    | 'Permission'
    | 500
    | 'Fund'
    | 600
    | 'CreditProduct'
    | 700
    | 'BankAccountProduct'
    | 800
    | 'BatchAssignment'
    | 900
    | 'PendingApprovals'
    | 1000
    | 'SignatureWorkFlow'
    | 1100
    | 'BillingAccount'
    | 1200

export interface PermissionRead extends Permission {
    id: string;
    typeDisplay: string;
    levelDisplay: string;
    resourceDisplay: string;
}

export type GetPermissionApiResponse = GetPermissionApiResponseSuccess | ApiResponseError;

export type GetPermissionApiResponseSuccess = {
    type: 'success';
    permissions: PermissionRead[];
};

export const toApiDataModel = (data: any) => {
    return data?.reduce((acc: { resource: any; level: number; type: string }[], item: any) => {
        item.readPermission &&
            acc.push({
                resource: item.resource,
                level: permissionLevel.indexOf(item.readPermission),
                type: 'Read',
            });
        item.createPermission &&
            acc.push({
                resource: item.resource,
                level: permissionLevel.indexOf(item.createPermission),
                type: 'Create',
            });
        item.updatePermission &&
            acc.push({
                resource: item.resource,
                level: permissionLevel.indexOf(item.updatePermission),
                type: 'Update',
            });
        item.deletePermission &&
            acc.push({
                resource: item.resource,
                level: permissionLevel.indexOf(item.deletePermission),
                type: 'Delete',
            });
        item.actions &&
            Object.keys(item.actions || {}).forEach((actionName) => {
                const currentActionValue = item?.actions[actionName] === true ? 'All' : 'None';
                return acc.push({
                    resource: item.resource,
                    level: permissionLevel.indexOf(currentActionValue),
                    type: actionName,
                });
            });
        return acc;
    }, []);
};

export async function postPermissionList(
    props: CreatePermissionProps,
    token: string
): Promise<AxiosResponse<PutRecordApiResponse, ApiResponseError>> {
    const { principalId, principalType, permissions, tenant } = props;

    var url = `/Permission/${principalType}/${principalId}`;
    url = tenant ? url + `?tenant=${tenant}` : url;

    const payload = toApiDataModel(permissions);

    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.post<PutRecordApiResponse>(url, payload, config);
}

export interface PermissionListProps extends PermissionRead, DefaultRecordDetails {
    principalId: string;
    type: number;
}

export function fromApiDataModel(userPerms: PermissionListProps[]) {
    const permissionList = getDefaultPermissionList();
    const permissionListWithUserPerms = permissionList.map((perm) => {
        let normalize = (permArray: any) => {
            if (permArray.length) {
                return permissionLevel[permArray[0].level];
            }
            return 'None';
        };
        perm.readPermission =
            perm.readPermission &&
            normalize(
                userPerms.filter(
                    (item) =>
                        item.resourceDisplay === perm.resource &&
                        PermissionTypes[item.type] === 'Read'
                )
            );
        perm.createPermission =
            perm.createPermission &&
            normalize(
                userPerms.filter(
                    (item) =>
                        item.resourceDisplay === perm.resource &&
                        PermissionTypes[item.type] === 'Create'
                )
            );
        perm.updatePermission =
            perm.updatePermission &&
            normalize(
                userPerms.filter(
                    (item) =>
                        item.resourceDisplay === perm.resource &&
                        PermissionTypes[item.type] === 'Update'
                )
            );
        perm.deletePermission =
            perm.deletePermission &&
            normalize(
                userPerms.filter(
                    (item) =>
                        item.resourceDisplay === perm.resource &&
                        PermissionTypes[item.type] === 'Delete'
                )
            );
        perm.actions &&
            Object.keys(perm.actions ?? {}).map((action) => {
                var res = normalize(
                    userPerms.filter(
                        (item) =>
                            item.resourceDisplay === perm.resource &&
                            PermissionTypes[item.type] === action
                    )
                );
                perm.actions[action] = res === 'All' ? true : false;
                return true;
            });
        return perm;
    });

    return permissionListWithUserPerms;
}

export async function getPermissionList(
    props: GetPermissionProps | undefined,
    selectedTenant: string | undefined,
    token: string
) {
    var url = `/Permission`;

    if (props !== undefined) {
        const { principalId, principalType } = props;
        url = url + `/${principalType}/${principalId}`;
        url = selectedTenant ? url + `?tenant=${selectedTenant}` : url;
    }

    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.get(url, config);
}

export function asText(permission: PermissionModel) {
    const groups: { [key: string]: string[] } = {
        All: [],
        MyGroups: [],
        OnlyMine: [],
    };
    Object.entries(permission).forEach(([key, value]) => {
        if (value !== 'None' && key.indexOf('Permission') > 0) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [permissionType, _] = key.split('Permission');
            groups[value]?.push(permissionType);
        }
    });

    const hasActionsPermissions = Object.entries(permission?.actions ?? {}).map((action) => {
        return action[1];
    }).some(x => x === true)

    const messages = Object.entries(groups)
        .filter(([_, permissions]) => permissions.length > 0)
        .map(([groupName, permissions]) => {
            return mapLevel(groupName, permissions);
        });

    if (messages.length === 0 && !!hasActionsPermissions) {
        return (
            <Typography variant="xs">
                Pode <PrimarySpan>executar ações</PrimarySpan>
            </Typography>
        )
    } else if (messages.length === 0) {
        return 'Não possui permissões';
    } else {
        return (
            <Typography variant="xs">
                Pode <PrimarySpan> {'' + textJoin(messages, 'e')} {hasActionsPermissions && "e executar ações"}</PrimarySpan>
            </Typography>
        );
    }
}

function textJoin(arr: string[], conjunction: string): ReactNode {
    if (arr.length === 0) {
        return '';
    } else if (arr.length === 1) {
        return arr[0];
    } else {
        const last = arr.pop();
        return `${arr.join(', ')} ${conjunction} ${last}`;
    }
}

function mapLevel(level: string, verbs: string[]): string {
    const verbMap: { [key: string]: string } = {
        create: 'criar',
        read: 'ler',
        update: 'atualizar',
        delete: 'excluir',
    };
    const levelMap: { [key: string]: string } = {
        All: 'todos os registros',
        MyGroups: 'registros de seus grupos',
        OnlyMine: 'seus próprios registros',
    };

    const verbsMapped = verbs.map((type) => verbMap[type]);
    const levelMapped = levelMap[level] || '';
    return textJoin(verbsMapped, 'e') + ' ' + levelMapped;
}

export function checkActionsPerms(
    actions: string,
    permissionsData: any,
    resource: string
): boolean {
    let permissions = permissionsData?.find(
        (perm: any) => perm?.resource === resource
    )?.actions;

    const getPermissions = Object.keys(permissions ?? {})
        .filter((key) => key.includes(actions))
        .reduce((obj, key) => {
            return Object.assign(obj, {
                [key]: permissions[key]
            });
        }, {});

    return Object.values(getPermissions)[0] === true ?? false;
}


export function getDefaultPermissionList(): PermissionModel[] {
    return [
        {
            resource: 'CreditNote',
            resourceDisplay: 'Operações de Crédito',
            createPermission: 'None',
            updatePermission: 'None',
            readPermission: 'None',
            deletePermission: 'None',
            actions: {
                SubmitApproval: false,
                Clone: false,
                AssigmentPreview: false,
                AssignRecord: false,
                Cancel: false,
                ComplianceApproval: false,
                CreditApproval: false,
                DraftPreview: false,
                InstrumentApproval: false,
                LiquidationApproval: false,
                LiquidationPaymentRevision: false,
                ManualUpdate: false,
                ReadDataSet: false,
                RegisterFgtsSaqueAniversario: false,
                RemoveBlock: false,
                RequestDataSet: false,
                RestoreRecord: false,
                SendAssigmentEmail: false,
                FinishSignature: false,
                SendNotification: false,
                SendToWedCred: false,
                SetFund: false,
                SignatureValidation: false,
                UploadDocs: false,
                AdminCancel: false,
                ApproveWarranty: false,
                ApproveMarginReserve: false,
                RestartWorkflow: false
            },
        },
        {
            resource: 'User',
            resourceDisplay: 'Usuários',
            readPermission: 'None',
            createPermission: 'None',
            updatePermission: 'None',
            deletePermission: 'None',
            actions: {
                EnableUser: false,
                DisableUser: false
            }
        },
        {
            resource: 'UserGroup',
            resourceDisplay: 'Grupo de Usuários',
            createPermission: 'None',
            updatePermission: 'None',
            readPermission: 'None',
            deletePermission: 'None',
        },
        {
            resource: 'BankAccountRequest',
            resourceDisplay: 'Solicitações de abertura de conta',
            createPermission: 'None',
            updatePermission: 'None',
            readPermission: 'None',
            deletePermission: 'None',
            actions: {
                OpenBankAccount: false,
                ComplianceApproval: false,
                InstrumentApproval: false,
                SignatureValidation: false,
                UploadDocs: false
            }
        },
        {
            resource: 'BankAccount',
            resourceDisplay: 'Contas Digitais',
            readPermission: 'None',
            updatePermission: 'None',
            actions: {
                ViewOperators: false,
                RequestNewOperator: false,
                ViewPendingOperators: false,
                AdminApproveOperator: false,
                ViewAllBankAccounts: false,
                RequestBankAccountLimit: false,
                ApproveBankAccountLimit: false,
                AdminApproveBeneficiary: false,
                TerminateAccount: false,
                AddAccountEntry: false
            },
        },
        {
            resource: 'LegalPerson',
            resourceDisplay: 'Pessoas Jurídicas',
            createPermission: 'None',
            updatePermission: 'None',
            readPermission: 'None',
            deletePermission: 'None',
            actions: {
                RequestDataSet: false,
                ReadDataSet: false
            }
        },
        {
            resource: 'NaturalPerson',
            resourceDisplay: 'Pessoas Físicas',
            createPermission: 'None',
            updatePermission: 'None',
            readPermission: 'None',
            deletePermission: 'None',
            actions: {
                RequestDataSet: false,
                ReadDataSet: false
            }
        },
        {
            resource: 'Person',
            resourceDisplay: 'Pessoas',
            readPermission: 'None',
        },
        {
            resource: 'Permission',
            resourceDisplay: 'Permissões',
            createPermission: 'None',
            updatePermission: 'None',
            readPermission: 'None',
            deletePermission: 'None',
            actions: {
                AllowUpdatePermissionsSameTenant: false
            }
        },
        {
            resource: 'Fund',
            resourceDisplay: 'Fundos',
            createPermission: 'None',
            updatePermission: 'None',
            readPermission: 'None',
            deletePermission: 'None',
        },
        {
            resource: 'CreditProduct',
            resourceDisplay: 'Produtos Crédito',
            createPermission: 'None',
            updatePermission: 'None',
            readPermission: 'None',
            deletePermission: 'None',
            actions: {
                IncludeBeneficiaryProduct: false
            }
        },
        {
            resource: 'BankAccountProduct',
            resourceDisplay: 'Produtos Conta',
            createPermission: 'None',
            updatePermission: 'None',
            readPermission: 'None',
            deletePermission: 'None',
        },
        {
            resource: 'BatchAssignment',
            resourceDisplay: 'Cessão em Lote',
            createPermission: 'None',
            updatePermission: 'None',
            readPermission: 'None',
            deletePermission: 'None',
            actions: {
                UploadDocs: false
            }
        },
        {
            resource: 'PendingApprovals',
            resourceDisplay: 'Central de Aprovação',
            readPermission: 'None'
        },
        {
            resource: 'SignatureWorkFlow',
            resourceDisplay: 'Processo de Assinatura',
            readPermission: 'None',
            createPermission: 'None',
            updatePermission: 'None',
            deletePermission: 'None',
            actions: {
                StartSignatureWorkFlow: false,
                ResendNotificationSignatureWorkFlow: false,
                CancelSignatureWorkFlow: false
            }
        },
        {
            resource: 'BillingAccount',
            resourceDisplay: 'Gestão de Carteiras',
            createPermission: 'None',
            updatePermission: 'None',
            readPermission: 'None',
            deletePermission: 'None',
            actions: {
                LiquidationApproval: false
            }
        },
    ];
}
