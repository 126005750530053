import React, { useState } from 'react';
import { FormProvider, useFormContext } from 'contexts/formContext';
import { bankDetailsListValidationSchema, defaultValuesBankDetails } from './BankDetailsSchema';
import { BankDetails } from './BankDetails';
import { BankDetailsList } from './BankDetailsList';
import { Drawer } from '@uy3/web-components';
import {
    ApiResponseError,
    ToastType,
    handleOnError,
    showSuccessToast,
    toastState,
} from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { useParams } from 'react-router';
import { useUserPermissionData } from 'contexts/userContext';
import { Grid } from '@mui/material';
import { GenericListHeader } from 'components/GenericListHeader/GenericListHeader';
import {
    useDeleteBankAccountMutation,
    usePersonsBankAccountMutation,
} from 'contexts/Persons/bankAccountContext';
import { PersonBankAccountData } from 'services/person/person.types';

type BankDetailsContainerProps = {
    resource: 'NaturalPerson' | 'LegalPerson';
};

export function BankDetailsContainer({ resource }: BankDetailsContainerProps) {
    const params = useParams();
    const isModeCreate = ['novo', 'nova'].includes(Object.values(params)[0] ?? '');
    const [selectedBankAccountIndex, setSelectedBankAccountIndex] = useState<number | undefined>(
        undefined
    );
    const [toast, setToast] = useState<ToastType>(toastState);
    const benkDetailsDefaultValues = defaultValuesBankDetails;
    const bankDetailsSchema = bankDetailsListValidationSchema();
    const { setValue, watch } = useFormContext();
    const bankAccounts = watch('bankAccounts') ?? [];
    const { hasPermission } = useUserPermissionData();

    const updateOrCreateType = isModeCreate ? 'Create' : "Update"
    const hasPermissionByCreateOrUpdate = hasPermission(resource, updateOrCreateType);

    const closeDrawerAccount = () => setSelectedBankAccountIndex(undefined);

    const onError = (error: ApiResponseError) => handleOnError(error, setToast);

    const description = 'Ótimo, agora você pode adicionar uma nova conta.';
    const onRegisterSuccess = () =>
        showSuccessToast('Conta atualizada com sucesso!', description, setToast);

    const personId = params?.personId ?? '';
    const { mutateAsync } = usePersonsBankAccountMutation({
        recordType: resource,
        onSuccess: onRegisterSuccess,
        onError,
        personId,
    });

    const setNewAccount = async (values: PersonBankAccountData) => {
        let newAccounts = [...bankAccounts];
        newAccounts[selectedBankAccountIndex!] = values;

        const operationTypeValue = values?.operationTypeValue;
        const keyPix =  operationTypeValue === 'Pix' ? values?.keyPix : null;

        const payload = {
            id: values?.id,
            bankCode: values?.bankCode,
            bankIspb: values?.bankIspb,
            account: values?.account,
            accountDigit: values?.accountDigit,
            agency: values?.agency,
            agencyDigit: values?.agencyDigit,
            type: values?.type,
            jointAccount: values?.jointAccount,
            pixKeyTypeValue: values?.pixKeyTypeValue,
            operationTypeValue,
            keyPix
        };

        if (!isModeCreate) {
            const response = await mutateAsync(payload);
            // @ts-ignore
            const bankAccountId: string = response[0] ?? "";
            newAccounts[selectedBankAccountIndex!] = {
                ...values,
                id: String(bankAccountId ?? null)
            };
        };
        setValue('bankAccounts', newAccounts);
        closeDrawerAccount();
    };

    const onDeleteSuccess = () => {
        const title = 'Conta removida com sucesso!';
        showSuccessToast(title, description, setToast);
    };

    const { mutateAsyncDelete } = useDeleteBankAccountMutation({
        recordType: resource,
        personId,
        onSuccess: onDeleteSuccess,
        onError,
    });

    async function onDeleteAccount(index: number) {
        let newAccounts = [...bankAccounts];
        newAccounts?.splice(index!, 1);

        const bankAccountId = bankAccounts[index]?.id;
        if (bankAccountId) await mutateAsyncDelete({ id: bankAccountId });
        setValue('bankAccounts', newAccounts);
    }

    const openDrawerCreateOrUpdate = (indexNumber: number | undefined) =>
        setSelectedBankAccountIndex(indexNumber ?? bankAccounts?.length);

    let isLegalPerson = resource === "LegalPerson";
    const defaultFormValue = bankAccounts[selectedBankAccountIndex!] ?? benkDetailsDefaultValues;

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <Grid mt={3} mb={4}>
                <GenericListHeader
                    enableAction={hasPermissionByCreateOrUpdate}
                    onClick={() => openDrawerCreateOrUpdate(bankAccounts?.length)}
                    title="Dados bancários"
                    titleButton="Adicionar conta"
                />
            </Grid>
            <BankDetailsList
                name="bankAccounts"
                onDeleteBankAccount={onDeleteAccount}
                openDrawer={openDrawerCreateOrUpdate}
                enableActions={hasPermissionByCreateOrUpdate}
            />
            <Drawer
                anchor="right"
                title="Adicionar conta bancária"
                open={selectedBankAccountIndex !== undefined}
                onClose={closeDrawerAccount}
            >
                <FormProvider
                    validationSchema={bankDetailsSchema}
                    defaultValues={defaultFormValue}
                    onChangeField={[
                        {
                            fieldName: 'operationTypeValue',
                            delegate: (
                                value: string | null,
                                setValue: (name: any, value: any) => void
                            ) => {
                                if (value === 'Transfer') {
                                    setValue('pixKeyTypeValue', null);
                                } else {
                                    setValue('type', null);
                                    setValue('bankCode', null);
                                    setValue('agency', null);
                                    setValue('accountDigit', null);
                                    setValue('account', null);
                                }
                            },
                        },
                    ]}
                    onSubmit={setNewAccount}
                >
                    <BankDetails isLegalPerson={isLegalPerson} onClose={closeDrawerAccount} />
                </FormProvider>
            </Drawer>
        </>
    );
}
